<template>
  <a :href="searchUrl">
    <slot />
  </a>
</template>

<script>
export default {
  setup(props, { attrs }) {
    const { query } = attrs;

    // replace spaces with url friendly +
    const getGoogleSearchUrl = (term) => {
      const searchTerm = term.replaceAll(' ', '+');
      const url = `https://www.google.com/search?q=${searchTerm}&tbm=isch`;

      // This replaces characters with the UTF-8 equivalent but not special characters such as +
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURI
      return encodeURI(url);
    };

    const searchUrl = getGoogleSearchUrl(query);

    return { searchUrl };
  },
};
</script>

<!--
<script>
  // NOTE: these functions were used to repair many broken or jank queries from Dan's Word Doc
  const prependProtocol = (s) => s.startsWith('http') ? s : `https://${s}`;

  const isDomain = (s) => {
    const domainRegex = /^((?!-)(https?:\/\/)?(www)?[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}.*$/g;

    return s.match(domainRegex);
  }

  // throws invalid URL error
  const domainIsGoogle = (s) => {
    s = prependProtocol(s);

    // if s has some url other than google.com return false
    const { hostname } = new URL(s); // get hostname from new url object

    return hostname === 'www.google.com';
  }

  /**
   * Returns an obj whether this is a google search or from another site.
   * If that url is a google search returns the used search query, otherwise
   * returns the full input url.
   *
   * { google: true, query: 'blah blah blah' }
   * { google: false, href: 'https://bing.com/hahaha' }
   */
  const getUrl = (href) => {
    let search = decodeURI(href);

    // base case - we have a query string, not a URI
    if (!isDomain(search)) {
      return { google: true, query: search };
    }

    // base case - we have a website other than google
    if (!domainIsGoogle(search)) {
      return { google: false, href: encodeURI(search) };
    }

    // let base search params object choose query params
    let params = new URLSearchParams(search);
    if (params.has('q')) {
      return getUrl(params.get('q')); // success, our URI was simple
    }

    const urlBaseStrings = [
      'www.google.com/url?',
      'www.google.com/search?',
      'www.google.com/?',
      'www.google.com/?gws_rd=ssl#',
      'www.google.com/images?',
      'www.google.com/imgres?',
    ];

    for (const base of urlBaseStrings) {
      // take out http/https then take out base string to look for query
      let s = search.replace('http://', '');
      s = s.replace('https://', '');
      s = s.replace(base, '');

      const p = new URLSearchParams(s);
      if (p.has('q')) {
        return getUrl(p.get('q')); // recurse - we could have nested urls
      }
    }

    // returning failure case
    return { google: false, query: 'null' };
  }

  document.querySelectorAll('a').forEach((el) => {
    const urlObj = getUrl(el.href);

    if (urlObj.query) {
      const searchEl = document.createElement('image-search'); // create image-search component
      searchEl.setAttribute('query', urlObj.query);
      searchEl.innerHTML = el.innerHTML; // keep contained text
      el.replaceWith(searchEl); // replace with new node
    } else if (urlObj.href) {
      el.setAttribute('href', `http://${urlObj.href}`); // update element with new href
    }
  });
</script>
-->
