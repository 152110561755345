<template>
  <!-- eslint-disable -->
  <article-wrapper heading="Plant Schedule">
    <h2>HARDSCAPE</h2>
    <h2>Arbors</h2>
    <span>
      <image-search query="pressure treated wood decks">
        Pressure Treated Wood
      </image-search>
      <image-search query="vinyl arbor">Vinyl</image-search>
      <image-search query="composite wood arbor">Composite
        Wood</image-search>
      <image-search query="entrance arbors">Arbor
        w/Gate</image-search>
      <image-search query="grape arbor">Grape
        Arbor</image-search>
      <image-search query="entrance arbors">Entrance
        Arbor</image-search>
    </span>
    <p></p>
    <p>
    </p>
    <h2>Architecture</h2>
    <span><a
        href="http://www.bing.com/images/search?q=band+board+flashing&amp;qs=AS&amp;sk=IM1AS2&amp;FORM=QBIR&amp;pq=band%20board&amp;sc=8-10&amp;sp=4&amp;qs=AS&amp;sk=IM1AS2#view=detail&amp;id=9264175E7B6CA41483C503E3EB37F80AA93F96FB&amp;selectedIndex=14">Band
        Board</a>
      <image-search query="basement egress window code">Basement
        Egress Window</image-search>
      <image-search query="board and batten siding">Board
        and Batten Siding</image-search><a
        href="http://www.bing.com/images/search?q=architectural+brackets&amp;FORM=HDRSC2">Brackets</a>
    </span><span>
    </span>
    <p></p>
    <p id="h.gjdgxs"><span>
        <image-search query="brick water table on house">Brick
          Water Table</image-search>
        <image-search query="cape cod architecture">Cape
          Cod</image-search>
        <image-search query="chippendale railing">Chippendale</image-search>
        <image-search query="conditioned crawl space">Conditioned
          Crawl Space</image-search>
        <image-search query="contemporary house architecture">Contemporary</image-search>
        <image-search query="courtyard style garage house plans">Courtyard
          Home</image-search>
        <image-search query="craftsman house">Craftsman</image-search>
      </span><span>
        <image-search query="dryvit">Dryvit</image-search>
        <image-search query="early colonial house">Early
          Colonial</image-search>
      </span><span>
        <image-search query="roof flashing details">Flashing</image-search>
        <image-search query="house foundation piers">Foundation
          Piers</image-search>
        <image-search query="hardy board siding">Hardy
          Board Siding</image-search>
        <image-search query="Open beam ceiling">Open
          Beam Ceiling</image-search>
        <image-search query="exterior operable plantation shutters">Operable
          Plantation Shutters</image-search>
        <image-search query="porte cochere">Porte
          Cochere</image-search>
        <image-search query="prow house plans">Prow</image-search>
        <image-search query="screened-in porch ideas">Screened-In
          Porch</image-search>
        <image-search query="skylights">Skylights</image-search>
        <image-search query="plexiglass roofs for patios">Plexiglass
          Roof</image-search>
      </span></p>
    <p id="h.30j0zll"><span>
        <image-search query="soffit">Soffit</image-search>
        <image-search query="What is a Sub Footing">Subfootings</image-search>
        <image-search query="threshold at entrance">Threshold</image-search>
        <image-search query="t1 eleven siding">T1-11
          Siding</image-search>
        <image-search query="window boxes">Window
          box</image-search>
        <image-search query="Ventless Fireplace">Ventless
          Fireplace</image-search>
        <image-search query="knee wall foundation">Knee
          Wall</image-search>
      </span></p>
    <p><span>
        <image-search query="front porch brackets">Front
          Porch with Brackets</image-search>
      </span></p>
    <p id="h.1fob9te">
    </p>
    <h2>Asphalt</h2>
    <span>
      <image-search query="gravel driveway">Gravel
        Driveway</image-search> <a href="http://en.wikipedia.org/wiki/Chipseal">Surface
        Treated Driveway</a>. Top coat with <image-search query="granite chips for driveways">Granite
        Chips</image-search>or other <image-search query="decorative gravel driveway">Decorative
        Gravel</image-search>. (Gravel should be angular and not round) <image-search query="asphalt driveways">Asphalt
      </image-search>
      <image-search query="how to saw cut asphalt">Saw
        Cut</image-search>and remove existing asphalt as shown. <image-search query="driveway sealing">Driveway
        Sealer</image-search>
      <image-search query="Asphalt with brown sealer">Asphalt
        w/Brown Sealer</image-search> Min. 2” Asphalt over min. 6” <image-search query="crusher run gravel">VDOT
        Std.21A</image-search> see <a href="http://www.asphaltpavement.org/driveways">Recommended
        base for Asphalt Driveway</a>base over compacted subgrade.
    </span><span><a href="http://www.asphaltpavement.org/driveways">Full
        Depth Asphalt</a>
      <image-search query="Asphalt Patch">Asphalt
        Patch</image-search>
    </span>
    <p></p>
    <p>
    </p>
    <h2>Borders/Edges</h2>
    <span>
      <image-search query="brick borders on asphalt driveways">Brick
        Border</image-search> <a
        href="http://www.belgard.biz/big.htm?path=images/belgard_shapes/big/dublin_combo_side.jpg&amp;width=500&amp;height=370">Dry-Laid
        6”X6” Concrete Pavers</a> <a
        href="http://sek.us.com/index.php?option=com_content&amp;view=article&amp;id=48&amp;Itemid=58">Paver
        Edge Restraint</a>
    </span>
    <p></p>
    <p id="h.3znysh7"><span>
        <image-search query="Concrete Paver Border on lawn">Concrete
          Paver Border on lawn</image-search>
        <image-search query="brick border lawn">Brick
          Border on lawn</image-search>
        <image-search query="Brick Border on Slate">Brick
          Border on Slate</image-search>
        <image-search query="rock borders around house">Rock
          Borders</image-search>
      </span></p>
    <p id="h.2et92p0"><span>
        <image-search query="concrete paver border on asphalt driveway">Concrete
          Paver Border on Asphalt</image-search>
      </span><span>
        <image-search query="trenched edge on lawn">Trenched
          Edge on lawn</image-search>
      </span></p>
    <p>
    </p>
    <h2>Brick</h2>
    <span>
      <image-search query="brick pavement">Brick
        Pavement</image-search>
      <image-search query="Brick Border">Brick
        Border</image-search>
      <image-search query="dry laid brick patio">Dry
        Laid Brick Patio</image-search>
      <image-search query="brick rowlock">Rowlock
        Cap</image-search>
      <image-search query="soldier course border">Soldier
        Course Border</image-search>
    </span>
    <p></p>
    <p><span>
        <image-search query="brick columns">Brick
          Column</image-search>
        <image-search query="brick rowlock on walkway">Brick
          Rowlock</image-search>
        <image-search query="point up mortar">Point
          Up Mortar</image-search>
        <image-search query="basket weave brick pattern">Basket
          Weave</image-search>
        <image-search query="Brick on Concrete Base">Brick
          on Concrete Base</image-search>
        <image-search query="soldier course brick">Soldier
          Course</image-search>
      </span></p>
    <p id="h.tyjcwt">
    </p>
    <h2>Bridges</h2><span>
      <image-search query="small bridge over creek">Small
        Bridge over creek</image-search>
    </span>
    <p></p>
    <p id="h.3dy6vkm">
    </p>
    <h2>Columns</h2>
    <span>
      <image-search query="brick columns">Brick
        Columns</image-search>
      <image-search query="stone entrance columns with lights">Stone
        Entrance Columns</image-search>
      <image-search query="brick driveway entrance columns">Brick
        Entrance Columns</image-search>
      <image-search query="Segmental Block Columns">Segmental
        Block Columns</image-search>
    </span>
    <p></p>
    <p id="h.1t3h5sf"><span>
        <image-search query="concrete urns">Concrete
          Urn</image-search>
        <image-search query="Stone Driveway entrance columns">
          Stone Driveway Entrance Columns
        </image-search>
        <image-search query="finials">Finials</image-search>
        <image-search query="concrete finials">Concrete
          Finials</image-search>
        <image-search query="pineapple finial">Pineapple
          Finial</image-search>
        <image-search query="dryvit">Dryvit</image-search>
        <image-search query="Driveway Entrance Apron">Entrance
          Apron</image-search>
        <image-search query="Iron Rails on Brick Columns">Iron
          Rails on Brick Columns</image-search>
      </span></p>
    <p><span>Columns to be 30” X 30” X 36” height. Caps, Lights and Gate to match
        house. </span></p>
    <p id="h.4d34og8">
    </p>
    <h2>Concrete</h2>
    <span>
      <image-search query="Decorative Concrete">Decorative
        Concrete</image-search>
      <image-search query="Plain Concrete">Plain
        Concrete</image-search>w/<image-search query="Stiff Brushed Finish on concrete">Stiff
        Brushed Finish</image-search>and <image-search query="Trowelled edge on concrete">Trowelled
        edge</image-search>
      <image-search query="Integral colored concrete">Integral
        Colored Concrete</image-search>
      <image-search query="concrete patio">Concrete
        Patio</image-search>
      <image-search query="stained concrete patio">Stained</image-search>
      <image-search query="stamped concrete">Stamped</image-search>
      <image-search query="textured finish on concrete patio">Textured</image-search>
      <image-search query="epoxy finish pool decks">Epoxy</image-search>
      <image-search query="spray crete">Spray
        Crete</image-search>
      <image-search query="exposed aggregate concrete">Exposed
        Aggregate</image-search>
      <image-search query="stamped concrete brick border">Stamped
        Brick Border</image-search>
      <image-search query="brick border on concrete patio">Brick
        Border</image-search>Possible location for <image-search query="stained concrete medallions">Medallion
      </image-search>
      Provide <a href="http://www.pavingexpert.com/concjnt1.htm">Dowelled
        Joints</a>into existing walls etc. to prevent uneven settling. <a
        href="http://www.bing.com/search?q=rebar%20in%20concrete&amp;pc=cosp&amp;ptag=A7B3F29FDD2FA4FCA80F&amp;form=CONMHP&amp;conlogo=CT3210127">Rebar</a>or
      <image-search query="welded wire mesh for concrete reinforcement">Woven
        Wire Mesh</image-search>or <image-search query="fiberglass reinforcement in concrete">Fiberglass
        Reinforcement</image-search>
    </span>
    <p></p>
    <p><span><a
          href="http://www.bing.com/search?q=footings%20and%20foundations&amp;pc=cosp&amp;ptag=A7B3F29FDD2FA4FCA80F&amp;form=CONMHP&amp;conlogo=CT3210127">Concrete
          Footing</a>
        <image-search query="turned down concrete slab">Turn
          Down Slab</image-search>
        <image-search query="concrete piers for patios">Concrete
          Piers</image-search>
        <image-search query="Pressure Washing concrete">Pressure
          Washing</image-search>
        <image-search query="concrete driveway entrance apron">Concrete
          Driveway Apron</image-search>
        <image-search query="Concrete foundation apron">Concrete
          Foundation Apron</image-search>
        <image-search query="Stone mulch along Foundation">Stone
          Foundation Apron</image-search>
        <image-search query="How to make stamped concrete less slippery">Slip
          Resistant</image-search>
      </span></p>
    <p><span>Provide <image-search query="control joints in concrete">Control
          Joints</image-search>(Control Joints should be min. ¼ thickness of slab) every 5’
        to 10’ and <image-search query="expansion joint in concrete">Expansion
          Joints</image-search></span><span>every 30’ and where concrete abuts building etc.
        18” border to match stone on house. Interior panels to match roof. Min. 2’
        wide square end on driveway return to prevent cracking. </span></p>
    <p id="h.2s8eyo1">
    </p>
    <h2>Concrete pavers</h2>
    <span>
      <image-search query="concrete pavers">Concrete
        Pavers</image-search>
      <image-search query="Engraved Memorial Pavers">Engraved
        Memorial Pavers</image-search> <a href="http://www.groundtradesxchange.com/pavers/polymeric_sand.htm">Polymeric
        Sand</a>
      <image-search query="cobblestones">Cobblestones</image-search>
    </span><span>
    </span>
    <p></p>
    <p><span>
        <image-search query="concrete paver borders">Concrete
          Paver Borders</image-search>to match house w/interior panels to match roof. <image-search
          query="soldier course border">Soldier
          Course</image-search>
      </span><span>
        <image-search query="paver restraints">Paver
          Restraint</image-search>
      </span><span> </span></p>
    <p id="h.17dp8vu">
    </p>
    <h2>Curbs</h2>
    <span>
      <image-search query="brick curb on driveway">Brick
        Curb</image-search>
    </span><span>
      <image-search query="Concrete Curb on driveway">Concrete
        Curb</image-search>
    </span><span>
      <image-search query="asphalt curb">Asphalt
        Curb</image-search>
    </span><span>
      <image-search query="granite curbstone">Granite
        Curbstone</image-search>
    </span><span>
      <image-search query="Curbstone on gravel drive">Curbstone</image-search>
    </span><span>
      <image-search query="Timber Curb">Timber
        Curb</image-search>
    </span>
    <p></p>
    <p>
    </p>
    <h2>Decks</h2>
    <span>
      <image-search query="Pressure Treated wood deck">Wood
        Deck</image-search>
      <image-search query="composite wood deck">Composite
        Deck</image-search>
      <image-search query="Evergrain Composite Wood Decking">Evergrain
        Deck</image-search>
      <image-search query="Trex Composite Wood Decking">Trex
        Deck</image-search>
      <image-search query="ipe decking">Ipe
        Deck</image-search>
    </span><span> Concrete Deck </span>
    <p></p>
    <p id="h.3rdcrjn"><span>
        <image-search query="Built-in bench seats on decks">Built-in
          bench seats</image-search>
        <image-search query="built in planters on decks">Built-in
          planters</image-search>
        <image-search query="wood deck stain">Stain</image-search>
        <image-search query="45 degree corner on deck">Clip
          Corner(s)</image-search>
        <image-search query="footings for deck">Footings</image-search>
        <image-search query="covered deck">Covered
          Deck</image-search>
        <image-search query="screened porch">Screened
          Porch</image-search>
      </span></p>
    <p id="h.26in1rg"><span>
        <image-search query="sunrooms">Sun
          Room</image-search>
        <image-search query="deck landing">Landing</image-search>
        <image-search query="waterproofing under a deck">Waterproof
          Underside of Deck</image-search>
        <image-search query="toenailing joists">Toenail</image-search>
        <image-search query="deck columns design">Deck
          Columns</image-search>
        <image-search query="cantilevered deck">Cantilevered
          Deck</image-search>
      </span></p>
    <p id="h.lnxbz9"><span>
        <image-search query="Treetop decks">Treetop
          Deck</image-search>
      </span><span>
        <image-search query="wrap step around corner of deck">Wrap
          steps around corner of deck</image-search>
      </span><span>
        <image-search query="concrete garage floor over basement">Concrete
          Deck</image-search>
      </span><span>
        <image-search query="polycarbonate panels lowes">Polycarbonate
          Panels</image-search>
      </span></p>
    <p id="h.35nkun2"><span>To avoid railings and meet code, backfill perimeter of deck so that
        vertical distance from deck to finished grade is less than 30”. (11) 7.5” risers as shown.
      </span></p>
    <p><span>Consider a patio instead of a deck. Decking is often more expensive that a
        patio due to footings, railings, support structure etc. In addition you have higher maintenance, the
        issue of how to hide that area and “critters” and mosquitoes hiding under there. Decks
        limit the flow of the space whereas patios easily flow into the yard.</span></p>
    <p>
    </p>
    <h2>Doors</h2>
    <span>
      <image-search query="Sliding Glass Door">Sliding
        Glass Door</image-search>
      <image-search query="Double Sliding Doors">Double
        Sliding Glass Doors</image-search>
      <image-search query="french doors">French
        Doors</image-search>
    </span>
    <p></p>
    <p id="h.1ksv4uv">
    </p>
    <h2>Drainage</h2>
    <span>
      <image-search query="Grass Swale">Mowable
        Grass Swale</image-search>
      <image-search query="crown on road">Crown</image-search>
      <image-search query="how to crown a driveway">Crown
        the driveway</image-search>
      <image-search query="foundation sump pump">Foundation
        Sump Pump</image-search>
    </span>
    <p></p>
    <p><span>
        <image-search query="cleanout">Cleanout</image-search>
        <image-search query="concrete ditch">Concrete
          Ditch</image-search>
        <image-search query="diversion ditch">Diversion
          Ditch</image-search>
        <image-search query="diversion berm">Diversion
          Berm</image-search>
        <image-search query="Dry Creek Bed">Dry
          Creek Bed</image-search>
        <image-search query="Swale">Swale</image-search>
      </span><span>
        <image-search query="level spreader detail">Level
          Spreader</image-search>
      </span><span> </span></p>
    <p id="h.44sinio"><span>
        <image-search query="ground gutters design">Ground
          Gutter</image-search><a href="http://en.wikipedia.org/wiki/Hardpan">Hard
          Pan</a>
        <image-search query="pop up drainage emitter">Pop-up
          drainage emitter</image-search>
        <image-search query="Riprap Ditch">Riprap
          Ditch</image-search>
        <image-search query="silt cloth">Silt
          Cloth</image-search>
        <image-search query="Rain chain">Rain
          Chain</image-search>
      </span></p>
    <p id="h.2jxsxqh"><span>
        <image-search query="curb-o-let">Curb-O-Let</image-search>
        <image-search query="foundation drainage board">Foundation
          Drainage Board</image-search>
      </span><span>
        <image-search query="bituthene">Bituthene</image-search>
        <image-search query="french drain">French
          Drain</image-search> Line trench w/<image-search query="landscape fabric">Landscape
          Fabric</image-search>, install <image-search query="corrugated perforated drain pipe">4”
          Perforated Pipe</image-search>in bottom and backfill w/well drained gravel(
      </span><span>
        <image-search query="57 gravel">#VDOT#57</image-search>).
        <image-search query="basement waterproofing">Basement
          Waterproofing</image-search>
      </span></p>
    <p><span>¼ “/Ft=2% 1/8”/Ft.=1% ½”/Ft=4%
        1”/Ft=8%</span></p>
    <p id="h.z337ya">
    </p>
    <h2>Drainage Pipes</h2><span class="c22">
      <image-search query="culvert">Culvert</image-search>
      <image-search query="Corrugated Plastic Storm Drain Pipe">Corrugated
        Plastic Storm Drain Pipe</image-search>
      <image-search query="pvc pipe">8”
        PVC Storm Drain</image-search>
      <image-search query="curb-o-let">Curb-O-Let</image-search>
    </span>
    <p></p>
    <p id="h.3j2qqm3"><span>
        <image-search query="corrugated metal pipe">Corrugated
          Metal Pipe</image-search>
        <image-search query="Smooth Wall HDPE Storm Drain Pipe">Smooth
          Wall HDPE Storm Drain Pipe</image-search>
        <image-search query="corrugated perforated drain pipe">4”
          Perforated Pipe</image-search>
        <image-search query="8 perforated drain pipe">8”
          Perforated Pipe</image-search>
      </span></p>
    <p id="h.1y810tw"><span>
        <image-search query="flared entrance for corrugated culvert">Flared
          Entrance</image-search>
        <image-search query="cleanout">Cleanout(s)</image-search>
      </span><span>
        <image-search query="headwalls on residential culvert">Headwalls</image-search>
        <image-search query="driveway culverts">Headwalls
          on ends of existing culvert</image-search>
      </span><span>
        <image-search query="mitered end culvert">Mitered
          Ends</image-search>
      </span></p>
    <p>
    </p>
    <h2>Drainage Inlets</h2>
    <span>
      <image-search query="DI-1 drop inlet">VDOT
        DI-1 drop inlet</image-search><a href="http://www.jensenprecast.com/Sewer-and-Storm-Drain/Drop-Inlets-p4010/">Medium
        Duty Drop Inlet(s)</a>
      <image-search query="Plastic Yard Inlet">Plastic
        Yard Inlet(s)</image-search>
      <image-search query="8 pvc drain grate">Grates</image-search>
    </span><span>
    </span>
    <p></p>
    <p id="h.4i7ojhp"><span>
        <image-search query="pool deck drain">Deck
          Drain</image-search>
        <image-search query="pool deck drainage grate">Pool
          Deck Drain(s)</image-search>
        <image-search query="trench drain">Trench
          Drain</image-search> “Bowl” out surrounding grade to provide minimum 6”
        ponding over top of grate. Connect all nearby downspouts etc. and direct into woods in min. 6”
        <image-search query="pvc pipe">PVC
          Storm Drain</image-search>.
      </span></p>
    <p id="h.2xcytpi">
    </p>
    <h2>Erosion Control</h2>
    <span>
      <image-search query="erosion control matting">Erosion
        Control Mat</image-search>
      <image-search query="wood fence with lattice top">
        Wood Fence with Lattice Top</image-search>
    </span><span>
      <image-search query="Permanent Erosion Control Mat">Permanent
        Erosion Control Mat</image-search> <a
        href="http://www.nagreen.com/erosion-control-products/bionet-extended-term-ecbs.php">Medium
        Duty Erosion Control Mat</a>
      <image-search query="jute mesh">Jute
        Mesh</image-search>
      <image-search query="grass seed netting">Seed
        Netting &amp; Straw etc.</image-search>
      <image-search query="Flagstone Lined drainage ditch">Flagstone
        Lined Channel</image-search>
      <image-search query="rip rap ditch">Riprap
        Ditch</image-search>
      <image-search query="riprap">Riprap</image-search>
    </span><span>
    </span>
    <p></p>
    <p id="h.1ci93xb"><span>
        <image-search query="grass parking pavers">Grass
          Pavers</image-search> Visit <a href="http://https://www.landscapesupplyva.com/">Landscape
          Supply</a>
      </span><span>near downtown for information and supplies. </span></p>
    <p id="h.3whwml4">
    </p>
    <h2>Espalier</h2>
    <span>
      <image-search query="espalier blue atlas cedar">Espaliered
        Blue Atlas Cedar</image-search>
      <image-search query="Espalier">Espalier</image-search>
      <image-search query="espalier magnolia grandiflora">Espaliered
        Magnolia</image-search>
      <image-search query="Espaliered Deodar Cedar">Espaliered
        Deodar Cedar</image-search>
    </span>
    <p></p>
    <p><span>
        <image-search query="espaliered fruit trees">Espaliered
          Fruit Trees</image-search>
      </span></p>
    <p>
    </p>
    <h2>Fences</h2>
    <span>
      <image-search query="decorative wood fence">Decorative
        Wood Fence</image-search> <a href="http://www.bing.com/images/search?q=Picket+Fence&amp;FORM=HDRSC2">Picket
        Fence</a>
      <image-search query="Shadow Board fence">Shadowboard</image-search>
      <image-search query="stockade fence">Stockade</image-search>
      <image-search query="picket fence">Picket</image-search>
      <image-search query="wood louver fence">Wood
        Louver</image-search>
    </span>
    <p></p>
    <p><span>
        <image-search query="three board fence">Three
          Board Fence</image-search>
        <image-search query="four board fence">Four
          Board Fence</image-search>
        <image-search query="pergola fence ideas">Pergola
          on top of fence</image-search>
        <image-search query="split rail fence with stone columns">Split
          Rail Fence w/Stone Columns</image-search>
        <image-search query="lattice">Lattice</image-search>
        <image-search query="chippendale railing">Chippendale
          Pattern</image-search>
        <image-search query="Black Anodized Aluminum fence">Black
          Anodized Aluminum</image-search>
        <image-search query="White Aluminum Pool Fence">White
          Anodized Aluminum</image-search>
        <image-search query="black wrought iron fence">Black
          Wrought Iron</image-search>
        <image-search query="chain link fence">Chain
          Link</image-search>
        <image-search query="black vinyl chain link">Black
          Vinyl Chain Link</image-search>
        <image-search query="Vinyl 3 board fence">Vinyl
          3 Board</image-search>
        <image-search query="wood guardrails">Wood
          Guardrail</image-search>(similar to Blue Ridge Parkway) This will create a safety barrier, hide
        the slope and create well defined edge of lawn. <image-search query="Deer Fence">Deer
          Fence</image-search>
        <image-search query="electric fence to stop deer">Electric
          Fence to Stop Deer</image-search>
        <image-search query="how to keep grass from growing under fence">Apron
          or Stone border under fence to eliminate weedeating</image-search>
      </span></p>
    <p id="h.2bn6wsx">
    </p>
    <h2>Fire</h2>
    <span>
      <image-search query="Outdoor Fireplace">Outdoor
        Fireplace</image-search>
      <image-search query="Outdoor fireplace with Wood storage area">Outdoor
        Fireplace w/Wood Storage</image-search>
      <image-search query="portable fire pits pictures">Portable
        Fire Pit</image-search>or <image-search query="Outdoor Fire pit">Built-in
        Fire Pit</image-search>
    </span>
    <p></p>
    <p id="h.qsh70q"><span>
        <image-search query="stone bench wall">Stone
          Bench Wall</image-search>
        <image-search query="natural stone fire pit ideas">Natural
          Stone Fire Pit</image-search>
        <image-search query="chiminea">Chimnea</image-search>
        <image-search query="outdoor gas log fireplace">Outdoor
          Gas Log Fireplace</image-search>
        <image-search query="fireplace in screened porch">Fireplace
          in Screen Porch</image-search>
      </span><span>
        <image-search query="Outdoor fireplace with/pergola">Outdoor
          fireplace w/pergola</image-search>
      </span><span>
        <image-search query="sunken fire pit area">Sunken
          Fire Pit</image-search>
      </span><span>
        <image-search query="sunken fire pit area ideas">Sunken
          fire pit</image-search>
      </span></p>
    <p class="c3 c10"><span class="c2 c23"></span></p>
    <p id="h.3as4poj">
    </p>
    <h2>Flagstone</h2><span>
      <image-search query="flagstone">Flagstone</image-search>
      <image-search query="Bluestone">Bluestone</image-search>
      <image-search query="Tumbled Bluestone">Tumbled
        Bluestone</image-search><a href="http://www.bvslate.com/faq.html">Buckingham
        Slate</a>
      <image-search query="dry-laid flagstone patio">Dry-laid
        Flagstone</image-search>
    </span>
    <p></p>
    <p id="h.1pxezwc"><span>
        <image-search query="Tennessee Crab Orchard Stone">Tennessee
          Crab Orchard Stone</image-search>
        <image-search query="flagstone patio set in grass">Flagstone
          Patio set in Lawn</image-search>
        <image-search query="laying flagstone in mortar">Flagstone
          on Concrete Base</image-search>
        <image-search query="how to point up existing flagstone walkway">Point
          Up</image-search>
      </span></p>
    <p id="h.49x2ik5">
    </p>
    <h2>Fountain</h2>
    <span>
      <image-search query="small fountains">Small
        Fountain</image-search>
      <image-search query="three tier fountains">Three
        Tier Fountain</image-search>
      <image-search query="Urns for fountains">Urn
        Fountain</image-search>
      <image-search query="Pondless watergarden">Pondless
        Watergarden </image-search>
      <image-search query="Birdbath">Birdbath</image-search>
      <image-search query="fountain against wall">Wall
        Fountain</image-search>
      <image-search query="cherub fountain for the garden">Cherub
        Fountain</image-search>
      <image-search query="Semi-Circular Fountain">Wall
        Fountain</image-search>
      <image-search query="wall mask fountain">Wall
        Mask</image-search>
      <image-search query="spray fountains">Spray
        Fountain</image-search>
      <image-search query="fountain pool base">Pool
        Base</image-search>
      <image-search query="rock column fountains">Rock
        Column</image-search>
    </span>
    <p></p>
    <p><span>Provide <image-search query="GFCI Electric">GFCI
          Electric service</image-search>and <image-search query="Frost free waterline">Frost-free
          Water Line</image-search> and/or a dedicated waterline w/<image-search query="water timer">Timer
        </image-search>or
        <image-search query="Automatic Float Valve">Float
          Valve</image-search>to automatically keep water basin full.
      </span></p>
    <p>
    </p>
    <h2>Front Porch</h2><span>
      <image-search query="front portico designs">Portico</image-search>
    </span>
    <p></p>
    <p id="h.2p2csry">
    </p>
    <h2>Furniture Planters Pots Etc.</h2>
    <span>
      <image-search query="adirondack chairs">Adirondack
        Chairs</image-search>
      <image-search query="large concrete planters">Concrete
        Planter</image-search>
      <image-search query="Glazed planters">Colorful
        Glazed Planter</image-search>
      <image-search query="concrete urns">Concrete
        Urn</image-search>
      <image-search query="bollard">Bollard</image-search>
      <image-search query="Porch swing">Swing</image-search>
      <a href="http://www.bing.com/images/search?q=Picnic+Table&amp;FORM=HDRSC2">Picnic
        Table</a>
      <image-search query="null">Concrete
        Block Bench</image-search>
      <image-search query="concrete garden bench mold">Concrete
        Garden Bench </image-search>
      <image-search query="garden benches">Garden
        Bench</image-search>
      <image-search query="garden planting bench">Planting
        Bench</image-search>
      <image-search query="outdoor flower pots">Flower
        Pots</image-search>
      <image-search query="Dining table with umbrella">Dining
        table with umbrella</image-search>
      <image-search query="Stone Bench">Stone
        Bench</image-search>
      <image-search query="Dragon Lady Holly">Decorative
        Bird Feeder</image-search>
    </span>
    <p></p>
    <p id="h.147n2zr"><span><a href="http://www.bing.com/images/search?q=House+Number&amp;FORM=HDRSC2">House
          Number</a>
        <image-search query="window boxes">Window
          Boxes</image-search>
        <image-search query="Outdoor TV">Outdoor
          TV</image-search>
      </span><span>
        <image-search query="stained glass panels for outdoor garden">Stained
          Glass Panel</image-search>
      </span><span>
        <image-search query="outdoor swinging daybed">Outdoor
          Swinging Daybed</image-search>
      </span></p>
    <p id="h.3o7alnk">
    </p>
    <h2>Garage/Utility Buildings</h2>
    <span>
      <image-search query="2 car garage plans">(2)Car
        Garage</image-search>
      <image-search query="(3)Car Garage">(3)Car
        Garage</image-search>
      <image-search query="recessed garage doors">Recessed
        Garage Doors</image-search>
    </span>
    <p></p>
    <p id="h.23ckvvd"><span>
        <image-search query="architectural carport">Decorative
          Carport</image-search>
      </span></p>
    <p><span>
        <image-search query="Custom Garage/Utility Buildings">Garage/Utility
          Building</image-search>
      </span><span>
        <image-search query="Reinforced Concrete Garage Floor">Reinforced
          Concrete Decking</image-search>
        <image-search query=" Storage Building with Hipped roof">Utility
          Building w/Hipped Roof</image-search>
        <image-search query="garden shed">Garden
          Shed</image-search>
      </span></p>
    <p>
    </p>
    <h2>Games</h2>
    <span>
      <image-search query="croquet">Croquet</image-search>
      <image-search query="badminton">Badminten</image-search>
      <image-search query="bocce ball">Bocce
        Ball</image-search>
      <image-search query="Croquet">Croquet</image-search>
      <image-search query="volleyball court">Volleyball
        Court</image-search>
      <image-search query="Outdoor Basketball Court">Basketball
        Court</image-search>
      <image-search query="badminton court">Badminton
        Court</image-search>
      <image-search query="play structures">Play
        Structure</image-search>
    </span>
    <p></p>
    <p id="h.ihv636">
    </p>
    <h2>Gates</h2>
    <span>
      <image-search query="decorative iron gates designs">Decorative
        Iron Gate</image-search>
      <image-search query="Synthetic Putting Green">Synthetic
        Putting Green</image-search>
    </span>
    <p></p>
    <p><span>
        <image-search query="wood gate">Wood
          Gate</image-search> w/short wing for personal access and larger wing for Vehicular and Lawn
        Mower access. <image-search query="Automatic Sensors to Open and Close Driveway Gates">Automatic
          Sensors to Open and Close Driveway Gates</image-search>
      </span></p>
    <p id="h.32hioqz">
    </p>
    <h2>Gardening</h2>
    <span>
      <image-search query="“Square Foot” Garden">“Square
        Foot” Garden Space</image-search>
      <image-search query="hotbeds">Hotbed/Cold
        Frame</image-search>
      <image-search query="garden shed">Garden
        Shed for Tools Etc.</image-search>
    </span>
    <p></p>
    <p id="h.1hmsyys"><span>
        <image-search query="kitchen garden">Kitchen
          Garden</image-search>
        <image-search query="garden compost bin">Compost
          Bin</image-search>
        <image-search query="garden faucet">Water
          Faucet</image-search>
        <image-search query="grape arbor">Grape
          Arbor</image-search>
        <image-search query="garden work bench">Work
          Bench</image-search>
        <image-search query="deer proof garden">Deer
          Proofed</image-search>
        <image-search query="asparagus plants growing">Asparagus</image-search>
      </span>
    </p>
    <p><span>
        <image-search query="fenced in colonial garden space">Fenced
          in Colonial Garden Space</image-search>
      </span><span>
        <image-search query="using a pergola to stop deer">Pergola
          to keep deer out of garden</image-search>
      </span><span>
        <image-search query="Garden Bench and storage bin">Garden
          Bench and storage bin</image-search>
      </span></p>
    <p id="h.41mghml"><span>
        <image-search query="herbs">Herb
          Garden</image-search>: w/<image-search query="chives">Chives</image-search>
        <image-search query="Lemon Verbena">Lemon
          Verbena</image-search>
        <image-search query="French Tarragon">French
          Tarragon</image-search>
        <image-search query="Calamint">Calamint</image-search>
        <image-search query="Chamomile">Chamomile</image-search>
        <image-search query="Bay Laurel">Bay
          Laurel</image-search>
        <image-search query="Lavender">Lavender</image-search>
        <image-search query="Lemon Balm">Lemon
          Balm</image-search>
        <image-search query="Catnip">Catnip</image-search>
        <image-search query="Oregano">Oregano</image-search>
        <image-search query="rosemary herb">Rosemary</image-search>
        <image-search query="rue plant">Rue</image-search>
        <image-search query="sage plant">Sage</image-search>
        <image-search query="Lavender Cotton">Lavender
          Cotton</image-search>
        <image-search query="savory herb">Savory</image-search>
        <image-search query="Germander">Germander</image-search>
        <image-search query="thyme herb">Thyme</image-search>
        <image-search query="asparagus plants">Asparagus</image-search>etc.
        <image-search query="blueberries shrubs">Blueberries</image-search>
        <image-search query="raspberry vines">Raspberries</image-search>
        <image-search query="Blackberries plants">Blackberries</image-search>
        <image-search query="Wineberry plants">Wineberries</image-search>
        <image-search query="Currants">Currants</image-search>
      </span><span>
        <image-search query="strawberries plants">Strawberries</image-search>
      </span>
    </p>
    <p><span>Fruit and Nut Trees: (1)<image-search query="hardy fig tree">Fig</image-search>
        (1)<image-search query="Bing Cherry trees">Bing
          Cherry</image-search> (1)<image-search query="Dwarf Apple trees">Dwarf
          Apple</image-search> (1)<image-search query="plum tree">Plum</image-search>
        (1)<image-search query="Pecan Tree">Pecan</image-search>
        (1)<image-search query="Dwarf Apple tree espalier">Dwarf
          Apple tree espalier</image-search> </span></p>
    <p id="h.2grqrue">
    </p>
    <h2>Gazebos</h2>
    <span>
      <image-search query="gazebo">Gazebo</image-search>
      <image-search query="octagon gazebo">Octagonal
        Gazebo</image-search>
      <image-search query="Pool House">Pool
        House</image-search>
      <image-search query="Formal Gazebo">Formal
        Gazebo</image-search>
      <image-search query="Fan ">Fan
        &amp; Lights</image-search>
    </span>
    <p></p>
    <p id="h.vx1227"><span>
        <image-search query="outdoor tv">Outdoor
          TV</image-search>
        <image-search query="rectangular gazebo">Rectangular
          Gazebo</image-search>
        <image-search query="gazebo with skylight">Skylight</image-search>
        <image-search query="gazebo with bar">Bar</image-search>
        <image-search query="driftwood gazebo">Driftwood
          Gazebo</image-search>
        <image-search query="Front porch with triple columns">Triple
          Columns</image-search>
      </span></p>
    <p>
    </p>
    <h2>Grading </h2>
    <span><a href="http://www.ehow.com/how_8176912_calculate-slope-ratio.html">4:1,
        3:1, 2:1 Slope</a> Slope grade away from house @ 1/8 1/4 inch/foot.</span>
    <p></p>
    <p id="h.3fwokq0">
    </p>
    <h2>Gravel/Decorative Stone</h2>
    <span>
      <image-search query="pea gravel">Pea
        Gravel</image-search>
      <image-search query="decorative gravel">Decorative
        Gravel</image-search><a href="http://https://www.bvslate.com/crushed-stone.html">Buckingham
        Slate Mulch</a>
    </span>
    <p></p>
    <p id="h.1v1yuxt"><span>
        <image-search query="crusher run gravel">Crusher
          Run</image-search>
        <image-search query="crusher run gravel">VDOT
          Std.21A</image-search>
        <image-search query="57 gravel">#57
          Gravel</image-search>
        <image-search query="riprap">Riprap</image-search>
        <image-search query="railroad ballast size">Railroad
          Ballast</image-search><a
          href="http://https://www.yellowpages.com/glade-hill-va/mip/jacks-mountain-quarry-8638976">Jack
          Mountain Stone</a>
      </span><span class="c7 c21">
        <image-search query="gray shale gravel">Gray
          Shale Gravel</image-search>
      </span><span>
        <image-search query="brick chips">Brick
          Chips</image-search>
      </span><span>
        <image-search query="lava rock">Lava
          Rock</image-search>
      </span></p>
    <p>
    </p>
    <h2>Hedge</h2>
    <span>
      <image-search query="rose hedge">Rose
        Hedge</image-search>
    </span>
    <p></p>
    <p>
    </p>
    <h2>Irrigation</h2>
    <span><a href="http://www.treegator.com/products/original/index.html">Gator
        Bags</a>
      <image-search query="irrigation system residential">Irrigation
        System</image-search>
      <image-search query="drip irrigation for window boxes">Drip
        Irrigation</image-search>
      <image-search query="Decorative Frost Free Yard Hydrant">Frost
        Free Hydrant</image-search> Apply seed in September/October and irrigate lightly 3times/day for
      2 weeks or until seed germinates (never let the ground dry out), then deeper soaking once/day for 4 more
      weeks. A simple <image-search query="Programmed Timer for irrigation">Programmed
        Timer</image-search>from the hardware store will be sufficient for this project.
    </span>
    <p></p>
    <p id="h.4f1mdlm">
    </p>
    <h2>Kitchen</h2>
    <span>
      <image-search query="outdoor kitchen">Outdoor
        Kitchen</image-search>
      <image-search query="outdoor grills">Grill</image-search>
      <image-search query="green egg grill">Green
        Egg</image-search>
      <image-search query="outdoor kitchen cabinets">Built-in
        Outdoor Cabinets</image-search>
      <image-search query="outdoor refrigerator">Refrigerator</image-search>
      <image-search query="outdoor ice maker">Icemaker</image-search>
      <image-search query="outdoor sink">Sink</image-search>
    </span><span>
      <image-search query="Outdoor Bar Area w/Stools">Outdoor
        Bar Area w/Stools</image-search>
      <image-search query="Built in outdoor Grill Insert">Grill
        Insert</image-search>
    </span>
    <p></p>
    <p id="h.2u6wntf">
    </p>
    <h2>Landscaping</h2><span>
      <image-search query="allee of trees">Allee</image-search>
      <image-search query="artificial turf">Artificial
        Turf</image-search>
      <image-search query="landscape berm ideas">Berm</image-search>
      <image-search query="canker on trees">Canker</image-search>
      <image-search query="grafted tree">Graft</image-search>
      <image-search query="too much mulch at base of trees">Haystack
        Mulch</image-search>
      <image-search query="landscape boulders">Landscape
        Boulders</image-search>
    </span>
    <p></p>
    <p><span>
        <image-search query="landscape berms">Landscaped
          Berms</image-search>
        <image-search query="landscape fabric">Landscape
          Fabric</image-search>
        <image-search query="rock garden">Rock
          Garden</image-search>
        <image-search query="Strangling Roots on nursery stock">Strangling
          Roots</image-search>
        <image-search query="topsoil">Topsoil</image-search>
        <image-search query="trenched edge landscape">Trenched
          Edge</image-search>
      </span></p>
    <p id="h.19c6y18"><span>
        <image-search query="parterre garden">Parterre
          Garden</image-search>
      </span></p>
    <p id="h.3tbugp1">
    </p>
    <h2>Lawn</h2>
    <span><a href="http://www.american-lawns.com/grasses/fescue_tall.html">Lawn
        areas</a><a href="http://en.wikipedia.org/wiki/Sod">Sod</a>
      <image-search query="Improved Tall Fescue">Improved
        Turf Type Tall Fescue</image-search>
      <image-search query="zoysia grass">Zoysia
        Grass</image-search>
      <image-search query="Bermudagrass">Bermudagrass</image-search>
    </span><span class="c5 c21">
      <image-search query="annual ryegrass">Annual
        Ryegrass</image-search>
    </span>
    <p></p>
    <p id="h.28h4qwu"><span>
        <image-search query="overseeding">Overseed</image-search>
        <image-search query="Slit Seed">Slit
          Seed</image-search><a
          href="http://www.bing.com/search?q=how%20to%20fertilize%20your%20lawn&amp;pc=cosp&amp;ptag=A7B3F29FDD2FA4FCA80F&amp;form=CONMHP&amp;conlogo=CT3210127">Fertilize</a>and
        treat all lawn areas w/<a
          href="http://www.bing.com/search?q=Preemergent&amp;pc=cosp&amp;ptag=A7B3F29FDD2FA4FCA80F&amp;form=CONMHP&amp;conlogo=CT3210127">Pre-Emergent</a>for
        Crabgrass and annual weed control and <a
          href="http://www.bing.com/search?q=Broadleaf%20Weed%20Killer&amp;pc=cosp&amp;ptag=A7B3F29FDD2FA4FCA80F&amp;form=CONMHP&amp;conlogo=CT3210127">Broadleaf
          Weed Killer</a> <a href="http://https://www.humeseeds.com/treelawn.htm">Growing
          grass under trees</a>
        <image-search query="turface soil">Turface</image-search>
        <a href="http://https://www.landscapesupplyva.com/">Landscape
          Supply</a><a href="http://https://www.soiltest.vt.edu/">Soil
          Test</a>
      </span></p>
    <p>
    </p>
    <h2>Lighting</h2>
    <span>
      <image-search query="low volt lighting transformers">Transformer</image-search>
    </span><span>
      <image-search query="low volt lighting">Low
        Volt Lighting</image-search>
      <image-search query="inground uplight for flagpole">Uplight
        on Flagpole</image-search>
      <image-search query="landscape moonlighting technique">Moonlighting</image-search>
      <image-search query="Low volt spotlight">Spotlights</image-search>
    </span><span>
    </span>
    <p></p>
    <p id="h.nmf14n"><span>
        <image-search query="path lights">Path
          Lights</image-search>
        <image-search query="uplights on trees">Uplights</image-search>
        <image-search query="outdoor residential post lighting">Post
          Light</image-search>
        <image-search query="post light with house number">Post
          Light w/House #</image-search>
        <image-search query="light bollards">Bollard
          Lights</image-search>
        <image-search query="Outdoor twinkle lights">Twinkle
          Lights</image-search>
      </span></p>
    <p><span><a
          href="http://www.bing.com/images/search?q=solar+landscape+lighting+ideas&amp;qs=IM&amp;form=QBIR&amp;pq=solar+landscape+lighting&amp;sc=8-24&amp;sp=3&amp;sk=IM2">Solar
          Lighting</a>
        <image-search query="soffit lights">Soffitt
          Light</image-search>
        <image-search query="spotlights on front of house">Spotlights
          on Front of House</image-search>
        <image-search query="motion activated outdoor light">Motion
          Activated Light</image-search>
        <image-search query="exterior recessed wall lights">Recessed
          Wall Lights</image-search>
        <image-search query="lights on stone columns">Lights
          on Stone Columns</image-search>
        <image-search query="Lights in concrete paver steps">Concrete
          Paver Step Lights</image-search>
        <image-search query="Solar Paver Lights in Walk">Solar
          Paver Lights in Walk</image-search>
        <image-search query="Lights on railing posts">Lights
          on railing posts</image-search>
      </span></p>
    <p><span>
        <image-search query="lights in segmental block walls">Lights
          in Segmental Block Walls</image-search>
        <image-search query="Lights in Stone Walls">Lights
          in Stone Walls</image-search>
      </span></p>
    <p>
    </p>
    <h2>Mailboxes</h2>
    <span>
      <image-search query="mailbox on stone column">Mailbox
        on Stone Column</image-search>
      <image-search query="Mailbox on 6X6 wood post">Mailbox
        on 6X6 wood post</image-search>. Stain to match house w/small flowerbed <a
        href="http://www.phy.duke.edu/~fortney/flowers/clematis/image-index.html">Clematis</a>,
      <image-search query="daffodils">Daffodils</image-search>,
      <a href="http://www.evergreennurseryinc.net/plants/iberis.htm">Candytuft</a>etc.
      Provide a <image-search query="concrete pavers">Concrete
        Paver(s)</image-search>or <image-search query="asphalt driveways">Asphalt</image-search>apron
      in front of mailbox to prevent mud puddle from forming. <image-search query="outdoor shower">Outdoor
        Shower</image-search>
    </span>
    <p></p>
    <p>
    </p>
    <h2>Maintenance</h2>
    <span>
      <image-search query="preen weed preventer">Preen</image-search>
      <image-search query="how to fertilize azaleas and rhododendrons">Fertilize
        Azlaeas</image-search>
      <image-search query="Lacewings on Azaleas">Lacewings
        on Azaleas</image-search>
      <image-search query="mulch against tree trunk">Do
        not mulch against base/crown/trunk of tree and shrubs</image-search>
      <image-search query="strangling roots on nursery stock">Strangling
        Roots</image-search>
      <image-search query="heart rot in trees">Heart
        Rot</image-search><a href="http://www.dictionaryofconstruction.com/definition/grub-axe.html">Grub
        Axe</a>
      <image-search query="bush hogging">Bush
        Hogging</image-search>
    </span>
    <p></p>
    <p>
    </p>
    <h2>Miscellaneous</h2>
    <span>
      <image-search query="Decorative Bird Feeder Post">Bird
        Feeder</image-search><a href="http://houselooks.net/wp-content/uploads/2009/09/Garden-Shed-1.jpg">Decorative
        Garden Shed</a>
      <image-search query="Golf Cart Path">Golf
        Cart Path</image-search>
      <image-search query="Using Garden Hose To lay out planting beds">Use
        a Garden Hose</image-search>
    </span>
    <p></p>
    <p id="h.37m2jsg"><span>
        <image-search query="dog doors">Dog
          Door</image-search>
        <image-search query="fiberglass boulders">Fiberglass
          Boulder</image-search>to cover well head. <image-search query="Gator carts">Gator
          Carts</image-search>
        <image-search query="Golf Cart">Golf
          Cart</image-search>
        <image-search query="ATV">ATV</image-search>
        <image-search query="Wood Chipper">Wood
          Chipper</image-search>
      </span></p>
    <p><span>
        <image-search query="outdoor shower">Outdoor
          Shower</image-search>
        <image-search query="rock garden ideas">Rock
          Garden</image-search>
        <image-search query="sinuous">Sinuous</image-search>
        <image-search query="soapstone countertops">Soapstone</image-search>
        <image-search query="outdoor stained glass panels">Stained
          Glass Panel</image-search><a
          href="http://www.bing.com/images/search?q=landscape+Vignette&amp;go=Submit&amp;qs=n&amp;form=QBIR&amp;pq=landscape+vignette&amp;sc=0-11&amp;sp=-1&amp;sk=">Vignette</a>
      </span>
      <span>
        <image-search query="wire mesh">Wire
          Mesh</image-search>
      </span><span> </span>
    </p>
    <p>
    </p>
    <h2>Mulch</h2>
    <span>
      <image-search query="Dyed Hardwood Bark mulch">Dyed
        Hardwood Bark Mulch</image-search>
      <image-search query="brown river stone mulch">Average
        3”Diameter Brown River Stone</image-search>
      <image-search query="pea gravel playground">Pea
        gravel</image-search>
      <image-search query="wood chips for ground cover">Wood
        Chips</image-search>
    </span>
    <p></p>
    <p><span>
        <image-search query="Triple Shredded Hardwood Bark mulch">Triple
          Shredded Hardwood Bark Mulch</image-search>
        <image-search query="Pine Needle mulch">Pine
          Needle Mulch</image-search>
        <image-search query="Pine Bark Nuggets">Pine
          Bark Nuggets</image-search>
        <image-search query="mulch ring around trees">Mulch
          Ring</image-search>
        <image-search query="Fibar mulch">Fibar</image-search>
      </span>
    </p>
    <p>
    </p>
    <h2>Native Plants</h2>
    <span>
      <image-search query="native mountain laurel">Mountain
        Laurel</image-search>
      <image-search query="Wild Virginia Blueberries">Wild
        Blueberries</image-search>
    </span>
    <p></p>
    <p id="h.1mrcu09">
    </p>
    <h2>Parking</h2>
    <span>(2)<image-search query="parking space dimensions">Guest
        Parking</image-search>/turn-around spaces shown 18’deep x 20’ wide. <image-search query="carports">Carport
      </image-search>
      <image-search query="Hammerhead parking turnaround">Hammerhead</image-search>
    </span>
    <p></p>
    <p id="h.46r0co2">
    </p>
    <h2>Pergolas</h2>
    <span>
      <image-search query="pergola">Pergola</image-search>
      <image-search query="vinyl pergola">Vinyl
        Pergola </image-search>
      <image-search query="composite wood">Composite
        Wood</image-search>
      <image-search query="pressure treated wood decks">Pressure
        Treated Wood</image-search>
      <image-search query="Pergola with Swing">Pergola
        w/ Swing</image-search>
    </span>
    <p></p>
    <p id="h.2lwamvv"><span>
        <image-search query="pergola with gate">Pergola
          entrance w/gate</image-search>
        <image-search query="colonnade pergola">Colonnade
          Pergola</image-search>
        <image-search query="pergola with bench seating">Pergola
          w/Bench Seat</image-search>
        <image-search query="pergola over garage doors">Pergola
          over Garage Doors</image-search>
        <image-search query="architectural brackets">Brackets</image-search>
      </span><span>
      </span></p>
    <p><span>
        <image-search query="canvas awnings for pergolas">Canvas
          Awnings for Pergolas</image-search>
        <image-search query="Composite Wood Pool Fence">Composite
          Wood</image-search>
        <image-search query="Pressure Treated Wood Pool Fence">Pressure
          Treated Wood</image-search>
        <image-search query="metal arbor with gate">Metal
          Arbor w/Gate</image-search>
      </span></p>
    <p id="h.111kx3o"><span>
        <image-search query="pergola gazebo">Pergola
          Gazebo</image-search>
        <image-search query="pergola entrance way">Pergola
          Entryway</image-search>
        <image-search query="pergola over deck">Pergola
          Over Deck</image-search>
        <image-search query="pergola details">Pergola
          Details</image-search>
        <image-search query="Stained Glass panels for pergolas">Stained
          Glass Panels</image-search>
        <image-search query="lighting pergolas pictures">Lighting</image-search>
      </span>
    </p>
    <p id="h.3l18frh">
    </p>
    <h2>Play</h2>
    <span>
      <image-search query="badminton court in yard">Badminten</image-search>
      <image-search query="lawn croquet">Croquet</image-search>
    </span><span>
      <image-search query="volleyball in yard">Volleyball</image-search>
      <image-search query="bocce court">Bocce
        Court</image-search>
    </span><span>
      <image-search query="basketball court layout">Basketball
        Court</image-search>
    </span><span>
      <image-search query="Corn Hole layout">Corn
        Hole</image-search>
    </span>
    <p></p>
    <p>
    </p>
    <h2>Porous Paving</h2><span>
      <image-search query="turf block">Turf
        Block</image-search>
      <image-search query="ez roll grass pavers">EZ
        Roll Grass Pavers</image-search>
      <image-search query="grasspave2">GrassPave2</image-search>
      <image-search query="Granite Paver Cubes">Granite
        Paver Cubes</image-search>
      <image-search query="plastic pavement mats">Pavement
        Grids</image-search>
    </span>
    <p></p>
    <p id="h.206ipza">
    </p>
    <h2>Pruning</h2><span><a href="http://www.pueblo.gsa.gov/cic_text/housing/prune/prune.htm">Drop
        Crotch or Crown Reduction Pruning</a><a
        href="http://www.bing.com/videos/search?q=how+to+prune+a+limb&amp;qpvt=how+to+prune+a+limb&amp;FORM=VDRE">How
        to Remove a Tree Limb</a>
      <image-search query="How to Prune Shrubs">How
        to Prune Shrubs</image-search>
      <image-search query="how to prune azaleas">Prune
        Azaleas</image-search>
    </span><span>
      <image-search query="Remove Strangling Roots">Remove
        Strangling Roots</image-search>
    </span><span> <a href="http://www.ehow.com/how_4489307_prune-boxwood.html">Prune
        Boxwoods</a></span><span> I recommend a three-year pruning program. In the
      first year, prune one third of the shrub. Make deep cuts and remove large stems to allow air and
      light into the shrub. The second year remove an additional third and continue to allow air and light
      into the shrub. By the third year you should be able to reduce the shrub as desired without drastic measures
      since there will be green leaves throughout the shrub. Always prune shrubs wider at base, narrower at
      top.</span>
    <p></p>
    <p>
    </p>
    <h2>Railings</h2>
    <span>
      <image-search query="deck railings">Deck
        Railings</image-search><a href="http://www.bing.com/images/search?q=Iron+Railings&amp;FORM=HDRSC2">Iron
        Railing/ Handrail</a>
      <image-search query="iron handrails for outdoor steps">Iron
        Handrail</image-search>
      <image-search query="Wood Guardrail on driveway">Wood
        Guardrail</image-search>
      <image-search query="glass deck railing">Glass
        Railing</image-search>
      <image-search query="cable deck railing">Cable
        Railing</image-search>
    </span>
    <p></p>
    <p>
    </p>
    <h2>Roofs</h2>
    <span>
      <image-search query="standing seam metal roof">Standing
        Seam Roof</image-search>
      <image-search query="roof over front door">Small
        Roof Over Front Door</image-search>
      <image-search query="hipped roof">Hipped</image-search>
      <image-search query="Gabled Roof">Gabled</image-search>
      <image-search query="Mansard Roof">Mansard</image-search>
      <image-search query="shed roof">Shed</image-search>
      <image-search query="Gambrel Roof">Gambrel</image-search>
      <image-search query="porte cochere">Porte
        Cochere</image-search><a
        href="http://www.bing.com/images/search?q=architectural+brackets&amp;FORM=HDRSC2">Brackets</a>
      <image-search query="prow roof definition">Prow
        Roof</image-search>
      <image-search query="Eyebrow in roof">Eyebrow</image-search>
      <a
        href="http://www.bing.com/search?q=Cupola&amp;pc=cosp&amp;ptag=A7B3F29FDD2FA4FCA80F&amp;form=CONMHP&amp;conlogo=CT3210127">Cupola</a>
      <image-search query="dormer windows">Dormer</image-search>
      <image-search query="corrugated plastic panels lowes">Corrugated
        Plastic Panels</image-search>
    </span>
    <p></p>
    <p id="h.4k668n3">
    </p>
    <h2>Screens</h2>
    <span>
      <image-search query="outdoor utility screens">Screen
        Heat Pumps</image-search>
      <image-search query="decorative garden screen panels">Decorative
        Garden Screen Panel</image-search>
      <image-search query="wood fence with lattice top">Wood
        Fence w/Lattice Top</image-search>
      <image-search query="pergola fence designs">Pergola</image-search>
    </span>
    <p></p>
    <p><span>Provide <a href="http://www.whirlpoolcomfort.com/Upload/46922G003.pdf">Minimum
          Clearances</a>for air intake and exhaust. Use <image-search query="styrofoam panels">Styrofoam
          Panels</image-search>between boards to reduce noise. <image-search query="trash can enclosure">Decorative
          Trash Can Enclosure</image-search>
        <image-search query="Living Walls">Living
          Walls</image-search>
        <image-search query="Hornbeam Hedge">Hornbeam
          Hedge</image-search>
      </span></p>
    <p id="h.2zbgiuw">
    </p>
    <h2>Sculpture</h2><span>
      <image-search query="outdoor statuary">Statuary</image-search>
      <image-search query="lawn sculptures">Sculpture</image-search>
      <image-search query="sundial garden sculpture">Sundial</image-search>
      <a href="http://www.bing.com/images/search?q=Plinth&amp;qpvt=Plinth&amp;FORM=IGRE">Plinth</a>
      <image-search query="concrete pedestal">Pedestal</image-search>
      <image-search query="outdoor wall decorations">Wall
        Decoration</image-search>
    </span><span>
      <image-search query="Birdbath">Birdbath</image-search>
    </span>
    <p></p>
    <p>
    </p>
    <h2>Soil</h2>
    <span>
      <image-search query="loam soil">Loam
        Soil</image-search>
      <image-search query="topsoil">Topsoil</image-search>
      <image-search query="Isinglass soil">Isinglass
        Soil</image-search>
      <image-search query="Soil Test">Soil
        Test</image-search> <a href="http://www.flower-gardening-made-easy.com/soil.html">Prepare
        Soil Min. 8” Deep Flower Bed</a><a href="http://www.wsconnelly.com/turf-and-ornamental/">Landscape
        Supply</a>
    </span>
    <p></p>
    <p><span>
        <image-search query="how to decompact soil for lawn">How
          to improve compacted soil</image-search>
      </span></p>
    <p id="h.1egqt2p">
    </p>
    <h2>Spas</h2>
    <span>
      <image-search query="4 to 6 person hot tub">Hot
        Tub</image-search>
      <image-search query="gunnite spa">4-6
        person Gunnite Spa</image-search>
      <image-search query="6 to 8 person hot tub">6
        to 8 person Spa</image-search>
      <image-search query="building deck around spa">Build
        Deck around Spa</image-search>
      <image-search query="Spa sunk into patio">Create
        niche in paver patio for Spa</image-search> Bottom of spa sits on ground. Top of Spa to be
      18” above decking around spa. Provide access to controls and pump and position so cover folds
      out of the way. Spa to be operable separate from pool in winter. <image-search query="hot tub cabana ideas">Cabana
      </image-search>
    </span>
    <p></p>
    <p id="h.3ygebqi">
    </p>
    <h2>Steps</h2>
    <span><a href="http://www.bing.com/images/search?q=Concrete+Steps&amp;qpvt=Concrete+Steps&amp;FORM=IGRE">Concrete
        Steps</a></span>
    <span><a
        href="http://www.bing.com/images/search?q=exterior+wood+steps&amp;qs=IM&amp;form=QBIR&amp;pq=exterior+wood+steps&amp;sc=6-19&amp;sp=1&amp;sk=">Wood
        Steps</a></span>
    <span><a
        href="http://www.bing.com/images/search?q=Wood+Steps+down+from+upper+deck&amp;go=Submit&amp;qs=n&amp;form=QBIR&amp;pq=wood+steps+down+from+upper+deck&amp;sc=0-0&amp;sp=-1&amp;sk=">Wood
        Steps down from deck</a>
      <image-search query="concrete paver steps">Concrete
        Paver Steps</image-search>
    </span>
    <p></p>
    <p id="h.2dlolyb"><span>
        <image-search query="Mortared stone steps">Mortared
          Stone Steps</image-search>
        <image-search query="stone slab steps">Dry-Laid
          Stone steps</image-search>
        <image-search query="brick steps">Brick
          Steps</image-search>w/ <image-search query="cheek wall">Cheek
          Walls</image-search>
        <image-search query="stamped concrete steps">Stamped
          Concrete Steps</image-search>
      </span></p>
    <p><span>
        <image-search query="brick steps with bluestone treads">Brick
          Steps with Bluestone Treads</image-search>
        <image-search query="gravel wood steps">Wood
          &amp; Gravel Steps</image-search>
      </span></p>
    <p><span>(8)7.5” <image-search query="risers for stairs">Risers</image-search>Steps
        shown in sets of (2) to avoid railing. <image-search query="step railings">Railings</image-search>
        <image-search query="Landing in exterior Wood Steps">Landing
          in Wood Steps</image-search>
        <image-search query="exterior spiral staircase">Spiral
          Staircase</image-search>
      </span></p>
    <p id="h.sqyw64">
    </p>
    <h2>Swimming Pools</h2>
    <span>
      <image-search query="gunite pool">Gunite</image-search>
    </span>
    <span>
      <image-search query="l shaped swimming pool designs">"L"
        Shaped</image-search>
      <image-search query="kidney shaped pool">Kidney</image-search>
    </span><span>
      <image-search query="Lagoon Shaped Pool">Lagoon</image-search>
    </span><span>
      <image-search query="oval pool">Oval</image-search>
      <image-search query="grecian shaped pool">Grecian</image-search>
    </span>
    <p></p>
    <p id="h.3cqmetx"><span>
        <image-search query="liner pool shapes">Liner
          Pool</image-search>
        <image-search query="lap pool">Lap
          Pool</image-search>
        <image-search query="counter current pool">Counter
          Current Pool</image-search>
      </span><span>
        <image-search query="pool spa">Pool
          Spa</image-search>
        <image-search query="pool spa waterfall pictures">Pool
          Spa w/Waterfall</image-search>
      </span></p>
    <p id="h.1rvwp1q"><span>
        <image-search query="screens for pool equipment">Screened
          area</image-search>for <image-search query="pool equipment">Pool
          Equipment</image-search>
        <image-search query="pool sun shelf">Sun
          Shelf</image-search>
        <image-search query="swimming pool slides">Slide</image-search>
        <image-search query="swimming pool slides">Steps</image-search>
        <image-search query="pool cabana">Pool
          Cabana</image-search>
        <image-search query="Pool House">Pool
          House</image-search>
        <image-search query="diving board for residential pool">Diving
          Board</image-search>
      </span></p>
    <p id="h.4bvk7pj"><span>
        <image-search query="Underwater Bench in swimming pool">Underwater
          Bench</image-search>
        <image-search query="waterfall into pool">Waterfall
          into Pool</image-search>
        <image-search query="Boulders in pool coping">Boulders
          in coping</image-search>
        <image-search query="dark bottom pool">Dark
          Bottom</image-search>
        <image-search query="bluestone coping">Bluestone
          Coping</image-search>
        <image-search query="vanishing edge pool">Vanishing
          Edge</image-search>
        <image-search query="slide into swimming pool">Waterslide</image-search>
        <image-search query="sloping pool entry">Sloping
          Pool Entry</image-search>
        <image-search query="Outdoor Shower">Outdoor
          Shower</image-search>
        <image-search query="jumping rock for swimming pool">Jumping
          Rock</image-search>
      </span><span>
        <image-search query="diving board">Diving
          Board</image-search>
      </span></p>
    <p><span><a href="http://https://www.aquariuspoolsinc.com/">Aquarius
          Pools</a></span><span> <a href="http://www.nationalpools.com/">National
          Pools</a></span><span> <a href="http://www.vapoolsales.com/">Virginia
          Pools</a></span><span>
        <image-search query="sheer descending waterfall">Sheer
          Descending Waterfall</image-search>
      </span></p>
    <p id="h.2r0uhxc"><span>Fence overlaps retaining wall until the wall reaches 4 feet in height
        <image-search query="Fencing to meet pool enclosure code reqirements">Fencing
          to meet pool enclosure code requirements</image-search>
      </span></p>
    <p></p>
    <p>
    </p>
    <h2>Tools</h2>
    <span>
      <image-search query="mattock lowes">Mattock</image-search>
      <image-search query="flat end sod shovel">Sod
        Shovel</image-search>
    </span>
    <p></p>
    <p>
    </p>
    <h2>Topiary </h2>
    <span>
      <image-search query="boxwood topiary trees">Boxwood
        Topiary</image-search>
      <image-search query="topiary">Topiary</image-search>
      <image-search query="espaliered fruit trees">Espaliered
        Fruit Trees</image-search>
    </span>
    <p></p>
    <p id="h.1664s55">
    </p>
    <h2>Trellises</h2>
    <span>
      <image-search query="trellis">Trellis</image-search>
      <image-search query="Decorative Bench w/ Trellis">Decorative
        Bench w/ Trellis</image-search>
      <image-search query="lattice fence with vines">Lattice
        w/Vines</image-search>
      <image-search query="Trellis w/Atlas Cedar">Trellis
        w/Atlas Cedar</image-search>
      <image-search query="garden arch trellis">Garden
        Arched Trellis</image-search>
    </span>
    <p></p>
    <p id="h.3q5sasy">
    </p>
    <h2>Utilities</h2>
    <span>
      <image-search query="residential water well construction">Well</image-search>
      <image-search query="Septic Tank(s)">Septic
        Tank(s)</image-search><a href="http://www.epa.gov/owm/septic/pubs/homeowner_guide_long.pdf">Septic
        Drain Field</a>
      <image-search query="Pump Back Septic System">Pump
        Back Septic System</image-search>
      <image-search query="distribution box septic">Distrubution
        Box</image-search>
      <image-search query="access risers septic tank">Access
        Riser</image-search>
      <image-search query="residential septic treatment plant">Residential
        Septic Treatment Plant</image-search>
      <image-search query="Heat Pumps">Heat
        Pumps</image-search>
      <image-search query="air conditioning unit">AC
        Units</image-search>
      <image-search query="Whole house generator">Generator</image-search>
      <image-search query="Underground Propane Tank">Underground
        Propane Tank</image-search>
      <image-search query="geothermal heat pump">Geothermal</image-search>
      <image-search query="utility buildings">Utility
        Building</image-search>
      <image-search query="Shadowboard fence">Decorative
        Wood Screen</image-search>
    </span><span>around Heat Pumps. </span>
    <p></p>
    <p><span>Provide <a href="http://www.whirlpoolcomfort.com/Upload/46922G003.pdf">Min.
          Clearances</a>for air intake and exhaust. Use <image-search query="styrofoam panels">Styrofoam
          Panels</image-search></span><span>between boards to reduce noise. </span></p>
    <p id="h.25b2l0r"><span><a href="http://www.aep.com/">AEP</a>
        <image-search query="how to run power lines underground">Run
          Power Underground</image-search>
        <image-search query="aep residential transformers">Electrical
          Transformer</image-search>
        <image-search query="Relocate Heat Pump">Relocate
          Heat Pump</image-search>
        <image-search query="screening trash cans">Screened
          area for Trash Cans</image-search>
        <image-search query="cleanout">Cleanout</image-search>
        <image-search query="how to relocate a heat pump">Relocate
          Heat Pump</image-search> Provide cleanout and Manhole access to septic tank.
      </span><span>
        <image-search query="Outdoor Water Spigot">Outdoor
          Water Spigot</image-search>
        <image-search query="Outdoor Electrical Outlet">Outdoor
          Electrical Outlet</image-search>
      </span></p>
    <p id="h.kgcv8k">
    </p>
    <h2>Walks</h2>
    <span>
      <image-search query="concrete walkway">Concrete
        Walk</image-search>
      <image-search query="concrete paver walkway">Concrete
        Paver Walk</image-search>
      <image-search query="brick walkway">Brick
        Walk</image-search>
      <image-search query="stamped concrete walkway">Stamped
        Concrete</image-search>
      <image-search query="Stained concrete walkway">Stained
        Concrete</image-search>
      <image-search query="flagstone walkway">Flagstone</image-search>
      <image-search query="Decorative gravel for paths">Decorative
        Gravel</image-search> <a
        href="http://www.bing.com/images/search?q=Stepping+Stones&amp;qpvt=Stepping+Stones&amp;FORM=IGRE">Stepping
        Stones</a>
      <image-search query="outdoor tile">Outdoor
        Tile</image-search>
      <image-search query="gravel pathway">Gravel
        Walk</image-search>
      <image-search query="woodland path ideas">Woodland
        Path</image-search>
    </span>
    <p></p>
    <p><span>
        <image-search query="stepping stones through lawn">Stepping
          Stones through lawn</image-search>
        <image-search query="stepping stones through mulch">Stepping
          Stones through mulch</image-search>
        <image-search query="pea gravel playground">Pea
          gravel</image-search>
        <image-search query="Iron Handrail along walks">Iron
          Handrail</image-search>
      </span></p>
    <p id="h.34g0dwd"><span>
        <image-search query="bluestone walkway with brick border">Bluestone
          w/ Brick Border</image-search>
      </span><span> <a href="http://https://www.bvslate.com/crushed-stone.html">Buckingham
          Slate Mulch</a></span></p>
    <p id="h.1jlao46">
    </p>
    <h2>Walls</h2>
    <span>
      <image-search query="retaining wall">Retaining
        Wall</image-search>
      <image-search query="boulder walls">Dry
        Stacked Boulder Walls</image-search>
      <image-search query="Dry Stacked Stone Wall">Dry
        Stacked Stone Wall</image-search>
      <image-search query="concrete cantilever retaining wall">Concrete
        Retaining Wall</image-search>
    </span>
    <p></p>
    <p id="h.43ky6rz"><span>
        <image-search query="concrete block seat wall">Concrete
          Block Sitting Wall</image-search>
        <image-search query="segmental block walls">Segmental
          Concrete Block Wall</image-search>
        <image-search query="segmental wall cap blocks">Cap
          Block</image-search>
        <image-search query="Mortared Rock Wall">Mortared
          Rock Wall</image-search>
        <image-search query="gabions baskets">Gabion
          Wall</image-search>
        <image-search query="pressure treated wood walls">Pressure
          Treated Wood Wall</image-search>
        <image-search query="brick retaining wall">Brick
          Wall</image-search>
        <image-search query="weep hole">Weep
          Hole</image-search>
        <image-search query="eldorado stone veneer">Eldorado
          Stone</image-search>
        <image-search query="Foundation Drain behind retaining Wall">Drains
          behind Wall</image-search>
      </span></p>
    <p><span>
        <image-search query="Stone Veneer on exterior walls">Stone
          Veneer to match house</image-search>
        <image-search query="exterior retaining wall cap">Wall
          Cap</image-search>
        <image-search query="parget">Parget</image-search>
      </span><span></span>
    </p>
    <p id="h.2iq8gzs"><span>Extend wall 18” above patio to create <a
          href="http://www.bing.com/images/search?q=Sitting+Wall&amp;qpvt=Sitting+Wall&amp;FORM=IGRE">Sitting
          Wall</a>
        <image-search query="Glue a Cap Block on top of Wall">Glue
          a Cap Block on top of Wall</image-search>
      </span><span> </span></p>
    <p><span>
        <image-search query="12 cinder block">12”
          Cinder Block</image-search>
        <image-search query="cinder block retaining wall">Cinder
          Block Retaining Wall</image-search> Install wall on <a
          href="http://www.cincinnati-oh.gov/bldginsp/downloads/bldginsp_pdf9790.pdf">Concrete
          Footing</a>
      </span><span>with rebar up into blocks, grout all cores. Finished
        grade on downhill side of wall to be max. 30” below patio to avoid railings.</span></p>
    <p>
    </p>
    <h2>Watergardens</h2>
    <span>
      <image-search query="Rock Waterfall">Rock
        Waterfall</image-search>
      <image-search query="Pondless Watergarden">Pondless
        Watergarden</image-search>
      <image-search query="rubber liner for ponds">Rubber
        Liner</image-search>
      <image-search query="small water garden">Small
        Watergarden</image-search>
      <image-search query="small fountains for garden">Fountain</image-search>
      <image-search query="Birdbath">Birdbath</image-search>
      <image-search query="small pond">Small
        Pond/Watergarden</image-search>
      <image-search query="rain garden">Rain
        Garden</image-search>
      <image-search query="Water Lilies">Water
        Lilies</image-search>
      <image-search query="Ultraviolet for water gardens">Ultraviolet
        Filter</image-search>
      <image-search query="watergarden skinmmer">Skimmer</image-search>
      <image-search query="spitting fountains for ponds">Spitting
        Fountain</image-search>
    </span>
    <p></p>
    <p id="h.xvir7l"><span>Provide <image-search query="GFCI Electric">GFCI
          Electric service</image-search>and <image-search query="Frost free waterline">Frost-free
          Water Line</image-search> and dedicated waterline w/<image-search query="water timer">Timer</image-search>or
        <image-search query="Automatic Float Valve">Float
          Valve</image-search>to automatically keep water basin full. <a
          href="http://www.sdpondandgarden.com/Rubber-Paint/Herco-Rubber-Paint-p-478.html">Herco
          Rubber Paint</a>
        <image-search query="bubbling rock fountain kits">Bubbling
          Rock Fountain</image-search>
      </span><span>
        <image-search query="bridges) over gardens">Bridge(s)</image-search>
      </span>
    </p>
    <p id="h.3hv69ve"><span>Visit <a href="http://www.springdalewatergardens.com/">Springdale
          Watergardens</a>in Greenville, VA <image-search query="koi pond">Koi
          Pond</image-search>
        <image-search query="water lilies">Water
          Lilies</image-search>
      </span></p>
    <p>
    </p>
    <h2>Wildflowers</h2>
    <span>
      <image-search query="native virginia wildflowers">Native
        Virginia Wildflowers</image-search>
      <image-search query="Native Virginia Woodland Wildflowers">Native
        Virginia Woodland Wildflowers</image-search>
    </span>
    <p></p>
    <p class="c3 c10"><span class="c12 c32 c34"></span></p>
    <p><span class="c8">PLANT MATERIAL-----------------</span></p>
    <p><span>Final planting plan to be prepared when house is near completion and before final
        grading</span></p>
    <p id="h.1x0gk37"><span>(Position plantings to screen adjacent house). <a
          href="http://www.bing.com/images/search?q=Layered+Plantings&amp;qpvt=Layered+Plantings&amp;FORM=IGRE">Layered
          Plantings</a><a href="http://www.bartlett.com/locations/Roanoke-VA.cfm?a">Bartlett
          Tree Experts</a></span></p>
    <p><span><a href="http://www.smithmtn.com/ShorelineMgmt/Plan/Appendix_F_VDCR_Native_Plants.pdf">AEP
          Approved plants</a><a href="http://www.smithmtn.com/shorelinemgmt/Plan/UpdatedSMP03_05_14.pdf">SML
          Shoreline Management Plan</a></span><span>Plant Remediation Plan (Plant Replacement
        Schedule page 76)</span></p>
    <p><span><a href="http://www.playroanoke.com/parks-and-greenways/urban-forestry/">City
          of Roanoke Urban Forestry Program</a>
        <image-search query="shade trees">Shade
          Trees</image-search>
        <image-search query="flowering trees">Flowering
          Trees</image-search>
        <image-search query="flowering shrubs">Flowering
          Shrub Masses</image-search>
        <image-search query="foundation plantings">Foundation
          Plantings</image-search>
      </span></p>
    <p><span class="c18">DECIDUOS TREES &amp; SHRUBS</span></p>
    <p><span class="c16">LDT (0ver 40’ ht.)</span><span class="c22"> (Owner to
        choose variety and color) (Specimen w/central leader) <image-search query="Shade Trees">Shade
          Trees</image-search></span></p>
    <p><span>(1)<image-search query="baldcypress">Baldcypress</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="american beech">American
          Beech</image-search> (1)<image-search query="fagus sylvatica">European
          Beech</image-search></span></p>
    <p><span>(1)<image-search query="Fagus sylvatica 'Riversii'">‘Rivers’
          Purple Beech</image-search></span></p>
    <p><span>(1)<image-search query="variegated european beech">Variegated
          Beech</image-search></span></p>
    <p><span>(1)<image-search query="Heritage River Birch">‘Heritage’
          River Birch’</image-search></span></p>
    <p><span>(1)<image-search query="betula nigra">River
          Birch</image-search></span></p>
    <p><span>(1)<image-search query="Dawn Redwood">Dawn
          Redwood</image-search></span></p>
    <p><span>(1)<image-search query="american elm tree">American
          Elm</image-search></span></p>
    <p><span>(1)<image-search query="acer x freemanii 'Autumn Blaze'">‘Autumn
          Blaze’ </image-search></span><span class="c0 c20">
        <image-search query="acer x freemanii 'Autumn Blaze'">Freeman
          Maple</image-search>
      </span></p>
    <p id="h.4h042r0"><span>(1)<image-search query="Ginko biloba">Male
          Ginko</image-search> (1)<image-search query="nyssa sylvatica">Black
          Gum</image-search></span></p>
    <p><span>(1)<image-search query="liquidambar styraciflua">Sweet
          Gum</image-search></span></p>
    <p id="h.2w5ecyt"><span>(1)<image-search query="Gletitsia triacanthos 'Skyline'">‘Skyline’
          Honeylocust</image-search> (1)<image-search query="Gleditsia triacanthos 'Shademaster'">‘Shademaster’
          Honeylocust</image-search></span></p>
    <p><span>(1)<image-search query="aesculus hippocastanum">Horsechestnut</image-search>
        (1)<image-search query="cercidiphyllum japonicum">Katsura</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="linden greenspire">‘Greenspire’
          Linden</image-search></span></p>
    <p><span>(1)<image-search query="quercus alba">White
          Oak</image-search> (1)<image-search query="quercus macrocarpa">Bur
          Oak</image-search></span></p>
    <p><span>(1)<image-search query="quercus phellos">Willow
          Oak</image-search></span></p>
    <p id="h.1baon6m"><span>(1)<image-search query="quercus robur fastigiata">English
          Oak</image-search></span><span class="c14">(narrow upright) (1)</span><span>
        <image-search query="Northern Red Oak">Northern
          Red Oak</image-search>
      </span></p>
    <p><span>(1)<image-search query="quercus shumardii">Shumard
          Oak</image-search></span><span class="c14">(tolerates wet soils)
        (1)<image-search query="quercus virginiana live oak">Live
          Oak</image-search></span><span class="c14">(marginally hardy)</span></p>
    <p><span>(1)<image-search query="quercus nuttallii">Nuttall
          Oak</image-search></span><span class="c15 c5 c14">(brighter red fall color)</span></p>
    <p id="h.3vac5uf"><span>(1)<image-search query="acer rubrum">Red
          Maple</image-search></span></p>
    <p><span>(1)<image-search query="acer rubrum 'Summer Red'">‘Summer
          Red’ Red Maple</image-search> (1)</span><span class="c0 c19">
        <image-search query="armstrong red maple">‘Armstrong’
          Red Maple</image-search>
      </span><span class="c19">narrow</span></p>
    <p><span>(1)<image-search query="acer rubrum October Glory">‘October
          Glory’ Red Maple</image-search> (1)<image-search query="acer rubrum 'Red Sunset'">‘Red
          Sunset’ Red Maple</image-search></span></p>
    <p><span>(1)<image-search query="sophora japonica 'Regent'">Sophora</image-search></span><span></span>
    </p>
    <p id="h.2afmg28"><span>(1)<image-search query="acer saccharum">Sugar
          Maple</image-search></span></p>
    <p><span>(1)</span><span class="c0 c19">
        <image-search query="acer saccharum 'Green Mountain'">‘Green
          Mountain’ Sugar Maple</image-search>
      </span></p>
    <p><span>(1)<image-search query="acer saccharum Legacy">‘Legacy’
          Sugar Maple</image-search></span></p>
    <p><span>(1) <image-search query="london planetree">London
          Planetree</image-search></span></p>
    <p><span>(1)<image-search query="platanus occidentalis">Sycamore</image-search>
        (1)<image-search query="zelkova serrata">Zelkova</image-search></span>
    </p>
    <p><span class="c16">MDT (25 to 40’ ht.)</span><span class="c37 c22">
        (Owner to choose variety and color)</span></p>
    <p><span>(1)<image-search query="Weeping European Beech">European
          Weeping Beech</image-search></span></p>
    <p><span>(1)<image-search query="aesculus glabra">Buckeye</image-search></span><span></span>
    </p>
    <p id="h.pkwqa1"><span>(1)<image-search query="kwanzan cherry tree">‘Kwanzan’
          Cherry</image-search></span></p>
    <p><span>(1)<image-search query="prunus subhirtella pendula">Weeping
          Cherry</image-search></span></p>
    <p><span>(1)<image-search query="Autumnalis Cherry">‘Autumnalis’
          Cherry</image-search></span></p>
    <p><span>(1)<image-search query="Autumnalis Cherry">‘Yoshino’
          Cherry</image-search></span></p>
    <p><span>(1)<image-search query="lacebark elm tree">Lacebark
          Elm</image-search></span></p>
    <p id="h.39kk8xu"><span>(1)<image-search query="koelreuteria paniculata">Golden
          Rain Tree</image-search></span></p>
    <p><span>(1)<image-search query="american hornbeam tree">American
          Hornbeam </image-search></span></p>
    <p><span>(1)<image-search query="carpinus betulus fastigiata">Upright
          Hornbeam</image-search></span></p>
    <p><span>(1)<image-search query="Sunburst Honeylocust">‘Sunburst’
          Honeylocust</image-search></span></p>
    <p><span>(1)<image-search query="saucer magnolia tree">Saucer
          Magnolia</image-search></span></p>
    <p><span>(1)<image-search query="sweetbay magnolia tree">Sweetbay
          Magnolia </image-search></span></p>
    <p><span>(1)<image-search query="crimson king maple tree">‘Crimson
          King’ Maple</image-search></span></p>
    <p><span>(1)<image-search query="shantung maple">Shantung
          Maple</image-search></span></p>
    <p><span>(1)<image-search query="mimosa tree">Mimosa</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="quercus lyrata">Overcup
          Oak</image-search></span></p>
    <p><span>(1)<image-search query="sawtooth oak tree">Sawtooth
          Oak</image-search></span></p>
    <p><span>(1)<image-search query="parrotia persica">Parrotia</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Pyrus calleryana 'Cleveland Select '">‘Cleveland
          Select’ Pear</image-search></span></p>
    <p><span>(1)<image-search query="Carolina Silverbell">Carolina
          Silverbell</image-search></span></p>
    <p><span>(1)<image-search query="salix alba">‘Tristis’
          White Willow</image-search></span></p>
    <p id="h.1opuj5n"><span>(1)<image-search query="salix babylonica">Weeping
          Willow</image-search></span></p>
    <p><span>(1)<image-search query="salix niobe">Golden
          Weeping Willow</image-search></span></p>
    <p><span>(1) <image-search query="corkscrew willow">Corkscrew
          Willow</image-search></span></p>
    <p><span>(1)<image-search query="american yellowwood">American
          Yellowwood</image-search></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p id="h.48pi1tg"><span class="c16">SDT (10’ to 25’ ht.)</span><span class="c4">
        (Owner to choose variety and color) <image-search query="flowering trees">Flowering
          Trees</image-search>
        <image-search query="uplights on trees">Uplights</image-search>
      </span>
    </p>
    <p><span>(1)<image-search query="Betula Little King">‘Little
          King’ Birch</image-search></span></p>
    <p><span>(1)<image-search query="aesculus parviflora">Bottlebrush
          Buckeye</image-search></span></p>
    <p><span>(1)<image-search query="aesculus pavia red buckeye">Red
          Buckeye</image-search></span></p>
    <p id="h.2nusc19"><span>(1)<image-search query="crape myrtle">Crape
          Myrtle</image-search></span></p>
    <p><span>(1)</span><span class="c0 c36">
        <image-search query="Natchez Crape Myrtle">‘Natchez’
          White Crape Myrtle</image-search>
      </span></p>
    <p><span>(1)<image-search query="Lagerstroemia indica Dynamite">‘Dynamite’
          Red Crape Myrtle </image-search></span></p>
    <p><span>(1)</span><span class="c0 c20">
        <image-search query="Lagerstroemia indica Muskogee">‘Muskogee’
          Lavender Crape Myrtle</image-search>
      </span></p>
    <p><span>(1)</span><span class="c0 c20">
        <image-search query="Lagerstroemia Tuscarora">‘Tuscarora’
          Coral Pink Crape Myrtle</image-search>
      </span></p>
    <p><span>(1)<image-search query="cornus florida">Dogwood</image-search></span><span>
      </span></p>
    <p id="h.1302m92"><span>(1)<image-search query="cornus kousa">Kousa
          Dogwood</image-search></span></p>
    <p><span>(1)<image-search query="cornus mas">Corneliancherry
          Dogwood</image-search></span></p>
    <p><span>(1)<image-search query="franklinia alatamaha">Franklin
          Tree</image-search></span></p>
    <p id="h.3mzq4wv"><span>(1)<image-search query="chionanthus virginicus">Fringetree</image-search></span>
    </p>
    <p><span>(1)<image-search query="Goldenchain Tree">Goldenchain
          Tree</image-search></span></p>
    <p id="h.2250f4o"><span>(1)<image-search query="Winter King Hawthorn">‘Winter
          King’ Hawthorn</image-search></span></p>
    <p><span>(1)<image-search query="crataegus crus-galli inermis">Thornless
          Hawthorn</image-search></span></p>
    <p><span>(1)<image-search query="syringa reticulata ivory silk">Lilac
          Tree</image-search></span></p>
    <p><span>(1)<image-search query="magnolia stellata royal star">‘Royal
          Star’ Magnolia</image-search></span></p>
    <p><span>(1)<image-search query="magnolia liliiflora 'Jane'">‘Jane’
          Magnolia</image-search></span></p>
    <p><span>(1)<image-search query="acer buergerianum">Trident
          Maple</image-search></span></p>
    <p><span>(1)<image-search query="acer ginalla 'Flame'">Amur
          Maple</image-search></span></p>
    <p><span>(1)<image-search query="acer griseum">Paperbark
          Maple</image-search></span></p>
    <p id="h.haapch"><span>(1)<image-search query="acer palmatum">Japanese
          Maple</image-search></span></p>
    <p><span>(1)<image-search query="acer palmatum 'bloodgood'">‘Bloodgood’Japanese
          Maple </image-search></span></p>
    <p id="h.319y80a"><span>(1)<image-search query="thundercloud flowering plum">‘Thundercloud’
          Purple Plum</image-search></span></p>
    <p><span>(1)<image-search query="salix discolor">Pussywillow</image-search></span>
    </p>
    <p id="h.1gf8i83"><span>(1)<image-search query="cercis canadensis">Redbud</image-search></span>
    </p>
    <p id="h.40ew0vw"><span>(1)<image-search query="cercis canadensis 'forest pansy'">‘Forest
          Pansy’ Redbud</image-search></span></p>
    <p id="h.2fk6b3p"><span>(1)<image-search query="amelanchier canadensis">Serviceberry</image-search></span>
    </p>
    <p><span>(1)<image-search query="Heptacodium miconioides">Seven-Son
          Flower</image-search></span></p>
    <p><span>(1)<image-search query="cotinus coggygria">Smoketree</image-search></span>
    </p>
    <p><span>(1)<image-search query="cotinus coggygria 'velvet cloak'">Velvet
          Cloak Smoketree</image-search></span></p>
    <p><span>(1)<image-search query="Stewartia">Stewartia</image-search></span>
    </p>
    <p id="h.upglbi"><span>(1)<image-search query="styrax">Styrax</image-search></span>
    </p>
    <p><span>(1)<image-search query="witch hazel tree">Witchhazel</image-search></span>
    </p>
    <p id="h.3ep43zb"><span>(1)<image-search query="Harry Lauder’s Walking Stick">Harry
          Lauder’s Walking Stick</image-search></span></p>
    <p><span>(1)<image-search query="nannyberry tree">Nannyberry
          Viburnum</image-search></span></p>
    <p><span>(1)<image-search query="BlackHaw Viburnum">Blackhaw
          Viburnum</image-search></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">LDS (6’ to 10’ ht.)</span><span class="c4">
        (Owner to choose variety and color) (Edible Fruit) <image-search query="flowering shrubs">Flowering
          Shrubs</image-search></span></p>
    <p><span>(1)<image-search query="Native Flame Azalea">Native
          Flame Azalea</image-search></span><span class="c14">(orange)</span><span>
      </span></p>
    <p><span>(1)<image-search query="Native Pinxter Azalea">Native
          Pinxter Azalea</image-search></span><span class="c14">(pink)</span></p>
    <p><span>(1)<image-search query="highbush blueberry">Highbush
          Blueberry</image-search></span></p>
    <p id="h.1tuee74"><span>(1)<image-search query="euonymus alatus">Burning
          Bush</image-search></span></p>
    <p id="h.4du1wux"><span>(1)<image-search query="butterfly bush buddleia davidii">Butterfly
          Bush</image-search></span></p>
    <p><span>(1)<image-search query="aronia arbutifolia">Chokeberry</image-search></span>
    </p>
    <p><span>(1)<image-search query="medium sized crape myrtle">Medium
          Size Crape Myrtle</image-search></span></p>
    <p><span>(1</span><span class="c19">) </span><span class="c0 c19">
        <image-search query="Lagerstroemia x tonto">‘Tonto’
          Fuchsia Crape Myrtle</image-search>
      </span></p>
    <p><span>(1)<image-search query="Lagerstroemia Acoma">‘Acoma’
          White Crape Myrtle</image-search></span></p>
    <p><span>(1)<image-search query="Lagerstroemia indica Pink Velour">‘Pink
          Velour’ Crape Myrtle</image-search></span></p>
    <p><span>(1)</span><span class="c0 c19">
        <image-search query="Lagerstroemia indica Catawba">‘Catawba’
          Purple Crape Myrtle</image-search>
      </span></p>
    <p><span>(1)<image-search query="redtwig dogwood">Redtwig
          Dogwood</image-search></span></p>
    <p><span>(1)<image-search query="cornus sericea Flaviramea">Yellowtwig
          Dogwood</image-search></span></p>
    <p><span>(1)Elderberry</span></p>
    <p><span>(1)<image-search query="sorbaria sorbifolia">Falsespirea</image-search></span>
    </p>
    <p id="h.2szc72q"><span>(1)<image-search query="forsythia lynwood gold">Forsythia</image-search></span>
    </p>
    <p id="h.184mhaj"><span>(1)<image-search query="fothergilla major">Fothergilla</image-search></span>
    </p>
    <p><span>(1)<image-search query="ilex verticillata">Winterberry
          Holly</image-search></span></p>
    <p><span class="c20">(needs a male pollenater ‘</span><span class="c0 c20">
        <image-search query="ilex verticillata jim dandy">Jim
          Dandy</image-search>
      </span><span class="c20">’)</span></p>
    <p id="h.3s49zyc"><span>(1)<image-search query="winter honeysuckle">Honeysuckle
          Bush</image-search></span></p>
    <p><span>(1)<image-search query="pee gee hydrangea tree">Pee
          Gee Hydrangea</image-search></span></p>
    <p id="h.279ka65"><span>(1)<image-search query="limelight hydrangea">Limelight
          Hydrangea</image-search></span></p>
    <p><span>(1)<image-search query="lilac bush">Lilac</image-search></span>
    </p>
    <p id="h.meukdy"><span>(1)<image-search query="dwarf japanese maple">Dwarf
          Japanese Maple</image-search></span></p>
    <p><span>(1)<image-search query="full moon japanese maple">Full
          Moon Japanese Maple</image-search></span></p>
    <p><span>(1)<image-search query="mockorange">Mock
          Orange</image-search></span></p>
    <p><span>(1)<image-search query="physocarpus opulifolius">Ninebark</image-search></span>
    </p>
    <p><span>(1)<image-search query="chinese paperbush">Chinese
          Paper Bush</image-search></span></p>
    <p><span>(1)<image-search query="physocarpus opulifolius diabolo">Purple
          Leaved Ninebark</image-search></span></p>
    <p><span>(1)<image-search query="prunus x cistena">Purpleleaf
          Sand Cherry</image-search></span></p>
    <p><span>(1)<image-search query="chaenomeles speciosa">Quince</image-search></span>
    </p>
    <p><span>(1)<image-search query="Rose-of-Sharon">Rose-of-Sharon</image-search></span>
    </p>
    <p><span>(1)<image-search query="spiraea van houtte">Van
          Houtte Spirea</image-search></span></p>
    <p><span>(1)<image-search query="calycanthus floridus">Sweetshrub</image-search></span>
    </p>
    <p id="h.36ei31r"><span>(1)<image-search query="arrowwood viburnum">Arrowwood
          Viburnum</image-search></span></p>
    <p><span>(1)<image-search query="viburnum trilobum">Cranberrybush
          Viburnum</image-search></span></p>
    <p><span>(1)<image-search query="viburnum lantana mohican">‘Mohican’
          Viburnum</image-search></span></p>
    <p id="h.1ljsd9k"><span>(1)<image-search query="viburnum 'Blue Muffin'">‘Blue
          Muffin’ Viburnum</image-search></span></p>
    <p><span>(1)<image-search query="viburnum carlesii 'Cayuga'">Fragrant
          Viburnum</image-search></span></p>
    <p id="h.45jfvxd"><span>(1)<image-search query="viburnum plicatum tomentosum shasta">Shasta
          Viburnum</image-search></span></p>
    <p><span>(1)<image-search query="cotinus coggygria golden spirit">Golden
          Smoketree</image-search></span></p>
    <p id="h.2koq656"><span>(1)<image-search query="Snowball Viburnum">Snowball
          Viburnum</image-search></span></p>
    <p><span>(1)<image-search query="vitex agnus-castus">Vitex</image-search></span>
    </p>
    <p><span>(1)<image-search query="weigela florida">Weigela</image-search></span>
    </p>
    <p><span>(1)<image-search query="mexican feather grass">Black
          Lace Elderberry</image-search></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">MDS (3’ to 6’ ht.)</span></p>
    <p><span>(1)<image-search query="crimson pygmy barberry">‘Crimson
          Pygmy’ Barberry</image-search></span></p>
    <p><span>(1)<image-search query="rose glow barberry">‘Rose
          Glow’ Barberry</image-search></span></p>
    <p><span>(1)<image-search query="gold barberry">Gold
          Barberry </image-search></span></p>
    <p><span>(1)<image-search query="american beautyberry">Beautyberry</image-search></span>
    </p>
    <p><span>(1)<image-search query="cephalanthus occidentalis">Buttonbush</image-search></span>
    </p>
    <p><span>(1)<image-search query="black chokeberry">Black
          Chokeberry</image-search></span></p>
    <p id="h.zu0gcz"><span>(1)<image-search query="clethra alnifolia">Clethra</image-search></span>
    </p>
    <p id="h.3jtnz0s"><span>(1)<image-search query="edgeworthia">Edgeworthia</image-search></span>
    </p>
    <p id="h.1yyy98l"><span>(1)<image-search query="fothergilla mt airy">‘Mount
          Airy’ Fothergilla</image-search></span></p>
    <p><span class="c19">(1)</span><span class="c0 c19">
        <image-search query="ilex verticillata red sprite">‘Red
          Sprite’ Winterberry Holly</image-search>
      </span><span class="c15 c5 c19"></span></p>
    <p><span class="c20">(needs a male pollenater ‘</span><span class="c0 c20">
        <image-search query="ilex verticillata jim dandy">Jim
          Dandy</image-search>
      </span><span class="c20">’)</span></p>
    <p id="h.4iylrwe"><span>(1)<image-search query="hydrangea">Hydrangea</image-search></span>
    </p>
    <p id="h.2y3w247"><span>(1)<image-search query="Nikko Blue hydrangea">‘Nikko
          Blue’ Hydrangea</image-search></span></p>
    <p><span>(1)<image-search query="Annabelle Hydrangea">‘Annabelle’
          Hydrangea</image-search></span></p>
    <p><span>(1)<image-search query="hydrangea nigra">Black
          Stemmed Hydrangea</image-search></span></p>
    <p><span>(1)</span><span class="c0 c19">
        <image-search query="pee wee hydrangea">‘Pee
          Wee’ Oakleaf Hydrangea</image-search>
      </span></p>
    <p id="h.1d96cc0"><span>(1)<image-search query="lacecap hydrangea">Lacecap
          Hydrangea</image-search></span></p>
    <p id="h.3x8tuzt"><span>(1)<image-search query="little limelight hydrangea">Little
          Limelight Hydrangea</image-search></span></p>
    <p id="h.2ce457m"><span>(1)<image-search query="itea virginica 'henry's garnet'">‘Henry’s
          Garnet’ Itea</image-search></span></p>
    <p id="h.rjefff"><span>(1)<image-search query="jasminum nudiflorum">Winter
          Jasmine</image-search></span></p>
    <p><span>(1)<image-search query="kerria japonica">Kerria</image-search></span>
    </p>
    <p id="h.3bj1y38"><span>(1)<image-search query="knockout roses">‘Knockout’
          Rose</image-search></span></p>
    <p><span>(1)<image-search query="cotinus coggygria 'young lady'">Young
          Lady Smoketree</image-search></span></p>
    <p><span>(1)<image-search query="spirea prunifolia">Bridalwreath
          Spirea</image-search></span></p>
    <p id="h.1qoc8b1"><span>(1)<image-search query="spirea snowmound">Snowmound
          Spirea</image-search></span></p>
    <p><span>(1)<image-search query="spirea anthony waterer">Anthony
          Waterer Spirea</image-search></span></p>
    <p id="h.4anzqyu"><span>(1)<image-search query="syringa x persica">Persian
          Lilac</image-search></span></p>
    <p><span>(1)<image-search query="viburnum juddii">Judd
          Viburnum</image-search></span></p>
    <p id="h.2pta16n"><span>(1)<image-search query="Weigela variegata">Variegated
          Weigela</image-search></span></p>
    <p id="h.14ykbeg"><span>(1)<image-search query="weigela wine and roses">‘Wine
          &amp; Roses’ Weigela</image-search></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">SDS (under 3’ ht.)</span><span class="c4"> </span><span>(Owner to
        choose variety and color)</span></p>
    <p><span>(1)<image-search query="Caryopteris">Caryopteris</image-search></span>
    </p>
    <p id="h.3oy7u29"><span>(1)<image-search query="Lagerstroemia Chickasaw">Dwarf
          Crape Myrtle</image-search></span></p>
    <p><span>(1)</span><span class="c0 c20">
        <image-search query="Lagerstroemia Pocomoke">‘Pocomoke’
          Dwarf Pink Crape Myrtle</image-search>
      </span></p>
    <p id="h.243i4a2"><span>(1)<image-search query="nikko deutzia">‘Nikko’
          Deutzia</image-search></span></p>
    <p><span>(1)<image-search query="forsythia gold tide">‘Gold
          Tide’ Forsythia</image-search></span></p>
    <p id="h.j8sehv"><span>(1)<image-search query="fothergilla gardenii 'blue mist'">‘Blue
          Mist’ Fothergilla</image-search></span></p>
    <p><span>(1)<image-search query="flower carpet roses">Flower
          Carpet Rose</image-search></span></p>
    <p id="h.338fx5o"><span>(1)<image-search query="Dwarf Spriea">Dwarf
          Spirea</image-search></span></p>
    <p><span>(1)<image-search query="spirea goldmound">Gold
          Mound Spirea</image-search></span></p>
    <p id="h.1idq7dh"><span>(1)<image-search query="spirea little princess">‘Little
          Princess’ Spirea</image-search></span></p>
    <p><span>(1)<image-search query="spirea shirobana">‘Shirobana’
          Spirea</image-search></span></p>
    <p><span>(1)<image-search query="stephanandra incisa 'crispa'">Stephanandra</image-search></span>
    </p>
    <p><span>(1)<image-search query="weigela minuet">‘Minuet’
          Weigela</image-search></span></p>
    <p><span>(1)<image-search query="dwarf weigela midnight wine">‘Midnight
          Wine’ Weigela</image-search></span></p>
    <p><span>(1)<image-search query="dwarf florida variegata nana">Dwarf
          Variegated Weigela</image-search></span></p>
    <p class="c3 c10"><span class="c24 c14 c33"></span></p>
    <p><span class="c18">EVERGREEN TREES &amp; SHRUBS</span></p>
    <p><span class="c16">LET (over 40’ ht.)</span><span class="c22"> </span><span>(Plant in 2
        staggered rows @ 8’ O.C. within row, 8’ between rows) </span></p>
    <p><span>
        <image-search query="large specimen evergreen trees">Large
          Specimen Evergreen Tree</image-search>
      </span></p>
    <p><span>
        <image-search query="Typical Foundation Plantings">Typical
          Foundation Plantings</image-search>
      </span></p>
    <p id="h.42ddq1a"><span>(1)<image-search query="green giant arborvitae">Green
          Giant Arborvitae</image-search></span></p>
    <p><span>(1)<image-search query="blue atlas cedar">Atlas
          Cedar</image-search></span></p>
    <p><span>(1)<image-search query="Deodar Cedar">Deodar
          Cedar</image-search></span></p>
    <p><span>(1)<image-search query="chamaecyparis obtusa">Hinoki
          Falsecypress</image-search></span></p>
    <p><span>(1)<image-search query="Cunninghamia lanceolata">Chinafir</image-search></span><span>
      </span></p>
    <p id="h.2hio093"><span>(1)<image-search query="cryptomeria japonica">Cryptomeria</image-search></span><span></span>
    </p>
    <p><span>(1)<image-search query="pseudotsuga menziesii">Douglas
          Fir</image-search></span></p>
    <p><span>(1)<image-search query="canadian hemlock tree">Canadian
          Hemlock</image-search></span></p>
    <p><span class="c14">Treat for </span><span class="c0 c14">
        <image-search query="Wooly Adelgid">Wooly
          Adelgid</image-search>
      </span><span class="c14">as necessary.</span></p>
    <p><span>(1)<image-search query="magnolia grandiflora tree">Southern
          Magnolia</image-search></span></p>
    <p><span>(1)</span><span class="c0 c19">
        <image-search query="magnolia grandiflora 'Claudia Wannamaker'">'Claudia
          Wannamaker’ Magnolia</image-search>
      </span></p>
    <p><span>(1)<image-search query="magnolia grandiflora 'D.D. Blanchard'">'D.D.
          Blanchard’ Magnolia</image-search></span><span class="c20">(orange/brown underside of
        leaves)</span></p>
    <p><span>(1)<image-search query="norway spruce tree">Norway
          Spruce</image-search></span></p>
    <p><span>(1)<image-search query="serbian spruce tree">Serbian
          Spruce</image-search></span></p>
    <p><span>(1)<image-search query="colorado blue spruce tree">Colorado
          Blue Spruce</image-search></span></p>
    <p><span>(1)<image-search query="pinus rigida">Pitch
          Pine</image-search></span></p>
    <p><span>(1)<image-search query="pinus strobus">Eastern
          White Pine</image-search></span></p>
    <p><span>(1)<image-search query="loblolly pine tree">Loblolly
          Pine</image-search></span></p>
    <p><span class="c16">MET (25’ to 40’ ht.)</span><span class="c22"> (Owner
        to choose variety and color)(<image-search query="Evergreen Trees">Evergreen
          Trees</image-search></span><span>)</span></p>
    <p><span>(1)<image-search query="chamaecyparis nootkatensis">Alaskan
          Cedar</image-search></span></p>
    <p><span>(1)<image-search query="chamaecyparis nootkatensis pendula">Weeping
          Alaskan Cedar</image-search></span></p>
    <p><span>(1)<image-search query="eastern red cedar tree">Eastern
          Red Cedar</image-search></span></p>
    <p id="h.wnyagw"><span>(1)<image-search query="yoshino cryptomeria">Yoshino
          Cryptomeria</image-search></span></p>
    <p><span>(1)<image-search query="Radicans Cryptomeria">‘Radicans’
          Cryptomeria</image-search></span></p>
    <p><span>(1)<image-search query="cupressocyparis leylandii">Leyland
          Cypress</image-search></span></p>
    <p><span>(1)<image-search query="Gold leyland cypress">Golden
          Leyland Cypress</image-search></span></p>
    <p><span>(1)<image-search query="arizona cypress tree">Arizona
          Cypress</image-search></span></p>
    <p><span>(1) <image-search query="american holly tree">American
          Holly</image-search></span></p>
    <p><span>(1)<image-search query="ilex opaca 'Greenleaf'">‘Greenleaf’
          Holly</image-search></span></p>
    <p><span>(1)</span><span class="c0 c14">
        <image-search query="magnolia grandiflora Bracken's Brown Beauty">‘Bracken’s
          Brown Beauty’ Magnolia</image-search>
      </span></p>
    <p><span>(1)<image-search query="edith bogue magnolia">‘Edith
          Bogue’ Magnolia</image-search></span></p>
    <p><span>(1)<image-search query="pinus bungeana">Lacebark
          Pine</image-search></span></p>
    <p><span>(1)<image-search query="Swiss Stone Pine">Swiss
          Stone Pine</image-search></span></p>
    <p><span>(1)<image-search query="Japanese black pine">Japanese
          Black Pine</image-search></span></p>
    <p class="c3 c10"></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c13">SET (10’ to 25’ ht.)</span></p>
    <p><span>(1)<image-search query="Thuja occidentalis 'Nigra'">‘Nigra’
          Arborvitae</image-search></span></p>
    <p><span>(1)<image-search query="emerald green arborvitae">‘Emerald
          Green’ Arborvitae</image-search></span></p>
    <p><span>(1)<image-search query="chamaecyparis obtusa crippsii">Cripps
          Golden Cypress</image-search></span></p>
    <p><span>(1)<image-search query="boulevard cypress">Boulevard
          Cypress</image-search></span></p>
    <p><span>(1)<image-search query="cryptomeria black dragon">‘Black
          Dragon’ Cryptomeria</image-search></span></p>
    <p><span>(1)<image-search query="elaeagnus pungens">Thorny
          Elaeagnus</image-search></span></p>
    <p id="h.3gnlt4p"><span>(1)<image-search query="Dragon Lady Holly">‘Dragon
          Lady’ Holly</image-search></span></p>
    <p><span>(1)<image-search query="ilex Emily Bruner">‘Emily
          Bruner’ Holly</image-search></span></p>
    <p><span>(1)<image-search query="ilex mary nell">‘Mary
          Nell’ Holly</image-search></span></p>
    <p id="h.1vsw3ci"><span>(1)<image-search query="Foster Holly">Foster
          Holly</image-search></span></p>
    <p id="h.4fsjm0b"><span>(1)<image-search query="slender hinoki cypress">Slender
          Hinoki Cypress</image-search></span></p>
    <p id="h.2uxtw84"><span>(1)<image-search query="oakland holly">Oakland
          Holly</image-search></span></p>
    <p><span>(1)<image-search query="oakleaf holly">Oakleaf
          Holly</image-search></span></p>
    <p><span>(1)<image-search query="japanese black pine">Japanese
          Black Pine</image-search></span></p>
    <p id="h.1a346fx"><span>(1)<image-search query="Nellie Stevens Holly">‘Nellie
          Stevens’ Holly’</image-search></span></p>
    <p><span>(1)<image-search query="ilex cornuta 'burfordii'">Burford
          Holly</image-search></span></p>
    <p><span>(1)<image-search query="Robusta Green Juniper">‘Robusta
          Green’ Juniper</image-search></span></p>
    <p id="h.3u2rp3q"><span>(1)<image-search query="hollywood juniper">Hollywood
          Juniper</image-search></span></p>
    <p><span>(1)<image-search query="juniperus chinensis 'Hetzii Columnaris'">‘Hetz’Columnaris’
          Juniper</image-search></span></p>
    <p><span>(1)<image-search query="juniperus scopulorum 'moonglow'">‘Moonglow’
          Juniper’</image-search></span></p>
    <p><span>(1)<image-search query="juniperus scopulorum skyrocket">‘Skyrocket’
          Juniper</image-search></span></p>
    <p><span>(1)<image-search query="‘Little Gem’ Magnolia">‘Little
          Gem’ Magnolia</image-search></span></p>
    <p id="h.2981zbj"><span>(1)<image-search query="chindo viburnum">Chindo
          Viburnum</image-search></span></p>
    <p><span>(1)<image-search query="Leatherleaf Viburnum">Leatherleaf
          Viburnum </image-search></span></p>
    <p id="h.odc9jc"><span>(1)<image-search query="Southern Waxmyrtle">Southern
          Waxmyrtle</image-search></span></p>
    <p><span>(1)<image-search query="taxus hicksii">Hick’s
          Yew</image-search></span></p>
    <p><span>(1)<image-search query="taxus capitata">‘Capitata
          Pyramidal’ Yew</image-search></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">LES (6’ to 10’ ht.)(Owner to choose variety/color)
        (watch for <a href="http://www.umassgreeninfo.org/fact_sheets/wood_attackers/rhododendron_borer.html">Wood
          Borers</a>)</span></p>
    <p id="h.38czs75"><span>(1)<image-search query="aucuba japonica">Aucuba</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="gold dust aucuba">‘Gold
          Dust’ Aucuba</image-search></span></p>
    <p><span>(1)</span><span class="c0 c20">
        <image-search query="rhododendron 'George L. Tabor'">‘George
          L. Tabor’ Pink Azalea</image-search>
      </span></p>
    <p><span>(1)<image-search query="Wintergreen Barberry">Wintergreen
          Barberry</image-search></span></p>
    <p id="h.1nia2ey"><span>(1)<image-search query="myrica pensylvanica">Northern
          Bayberry</image-search></span></p>
    <p id="h.47hxl2r"><span>(1)<image-search query="buxus graham blandy">‘Graham
          Blandy’ Boxwood</image-search></span></p>
    <p><span>(1)<image-search query="buxus sempervirens">American
          Boxwood</image-search></span></p>
    <p id="h.2mn7vak"><span>(1)<image-search query="camellia japonica">Camellia</image-search></span>
    </p>
    <p><span>(1)<image-search query="cleyera japonica">Cleyera</image-search></span>
    </p>
    <p><span>(1)<image-search query="manhattan euonymus">‘Manhattan’
          Euonymus</image-search></span></p>
    <p id="h.11si5id"><span>(1)<image-search query="chamaecyparis obtusa 'filicoides'">Fernspray
          Falsecypress</image-search></span></p>
    <p><span>(1)<image-search query="needlepoint holly">‘Needlepoint’
          Holly</image-search></span></p>
    <p><span>(1)<image-search query="steeds holly">‘Steeds’
          Upright Holly</image-search></span></p>
    <p id="h.3ls5o66"><span>(1)<image-search query="sky pencil holly">‘Sky
          Pencil’ Holly</image-search></span></p>
    <p id="h.20xfydz"><span>(1)<a
          href="http://www.bing.com/images/search?q=Blue+Holly&amp;qpvt=Blue+Holly&amp;FORM=IGRE">Blue
          Holly</a></span></p>
    <p><span>(1)<image-search query="ilex x meserveae 'China Girl'">‘China
          Girl’ Holly</image-search></span><span class="c20">(needs male pollinator </span><span class="c0 c20">
        <image-search query="ilex x meserveae 'China Boy'">’China
          Boy’ Holly</image-search>
      </span><span class="c20">)</span></p>
    <p><span>(1)<image-search query="ilex x meserveae 'Blue Princess'">‘Blue
          Princess’ Holly</image-search></span><span class="c20">(needs male pollinator</span><span class="c0 c20">
        <image-search query="ilex x meserveae 'Blue Prince'">‘Blue
          Prince’ Holly</image-search>
      </span><span class="c15 c5 c20">)</span></p>
    <p><span>(1)<image-search query="blue point juniper tree">‘Blue
          Point’ Juniper</image-search></span></p>
    <p><span>(1)<image-search query="kalmia latifolia">Mountain
          Laurel</image-search></span></p>
    <p id="h.4kx3h1s"><span>(1)<image-search query="schip laurel">Schip
          Laurel</image-search></span></p>
    <p id="h.302dr9l"><span>(1)<image-search query="loropetalum chinense">Chinese
          Loropetalum</image-search></span></p>
    <p><span>(1)<image-search query="osmanthus x fortunei 'fruitlandii'">Holly
          Osmanthus</image-search></span></p>
    <p><span>(1)<image-search query="photinia x fraseri">Fraser
          Photinia</image-search></span></p>
    <p><span>(1)<image-search query="pieris japonica">Pieris</image-search></span>
    </p>
    <p><span>(1)<image-search query="japanese privet">Japanese
          Privet</image-search></span></p>
    <p><span>(1)<image-search query="wax leaf privet">Waxleaf
          Privet</image-search></span></p>
    <p><span>(1)<image-search query="pyracantha coccinea">Pyracantha</image-search></span>
    </p>
    <p id="h.1f7o1he"><span>(1)<image-search query="rhododendron">Rhododendron</image-search></span>
    </p>
    <p><span>(1)<image-search query="rhododendron catawbiense album">White
          Rhododendron</image-search></span></p>
    <p><span>(1)</span><span class="c0 c20">
        <image-search query="rhododendron nova zembla">‘Nova
          Zembla’ Red Rhododendron</image-search>
      </span></p>
    <p><span>(1)</span><span class="c0 c20">
        <image-search query="rhododendron catawbiense">Catawba
          Purple Rhododendron</image-search>
      </span></p>
    <p><span>(1)</span><span class="c0 c20">
        <image-search query="rhododendron catawbiense 'boursault'">‘Boursault’
          Lavender Rhododendron</image-search>
      </span></p>
    <p><span>(1)</span><span class="c0 c20">
        <image-search query="‘English Roseum’ Light Rose Rhododendron">‘English
          Roseum’ Rose Rhododendron</image-search>
      </span></p>
    <p><span>(1)</span><span class="c0 c14">
        <image-search query="rhododendron catawbiense Roseum Elegans">‘Roseum
          Elgans’ Purple Rhododendron</image-search>
      </span></p>
    <p><span>(1)<image-search query="dwarf alberta spruce">Dwarf
          Alberta Spruce</image-search></span></p>
    <p id="h.3z7bk57"><span>(1)<image-search query="Burkwood Viburnum">Burkwood
          Viburnum</image-search></span></p>
    <p id="h.2eclud0"><span>(1)<image-search query="prague viburnum">Prague
          Viburnum</image-search></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">MES (3’ to 6’ ht.)</span><span class="c4"> </span><span>(Owner to
        choose variety and color)</span></p>
    <p id="h.thw4kt"><span>(1)<image-search query="abelia x grandiflora">Abelia</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Edward Goucher Abelia">‘Edward
          Goucher’ Abelia</image-search></span></p>
    <p><span>(1)</span><span class="c0 c20">
        <image-search query="Thuja orientalis 'Aurea Nana'">Dwarf
          Golden ‘Aurea Nana’ Arborvitae</image-search>
      </span><span class="c20"></span>
    </p>
    <p><span>(1)<image-search query="Thuja occidentalis 'Woodwardii'">‘Woodwardii’
          Arborvitae</image-search></span></p>
    <p id="h.3dhjn8m"><span>(1)<image-search query="dwarf aucuba">Dwarf
          Aucuba</image-search></span></p>
    <p id="h.1smtxgf"><span>(1)<image-search query="Azaleas">Azaleas</image-search></span>
    </p>
    <p><span>(1)</span><span class="c0 c19">
        <image-search query="rhododendron 'Delaware Valley White'">‘Delaware
          Valley White’ Azalea</image-search>
      </span><span class="c19"></span></p>
    <p><span>(1)<image-search query="rhododendron 'Blaauw's Pink'">‘Blaauw’s
          Pink’ Azalea</image-search></span></p>
    <p><span>(1)<image-search query="azalea 'Elsie Lee'">‘Elsie
          Lee’ Azalea</image-search></span></p>
    <p><span>(1)<image-search query="rhododendron 'Fashion'">‘Fashion’
          Salmon Azalea</image-search></span></p>
    <p><span>(1)<image-search query="rhododendron 'Formosa'">‘Formosa’
          Purple/Red Azalea</image-search></span></p>
    <p><span>(1)<image-search query="rhododendron 'Hino Crimson'">‘Hino
          Crimson’ Red Azalea</image-search></span></p>
    <p><span>(1)<image-search query="rhododendron 'Lavender Formosa'">‘Lavender
          Formosa’ Azalea</image-search></span></p>
    <p id="h.4cmhg48"><span>(1)<image-search query="encore azalea">Encore
          Azaleas</image-search></span></p>
    <p><span>(1)<image-search query="william penn barberry">‘William
          Penn’ Barberry</image-search></span></p>
    <p><span>(1)<image-search query="Warty Barberry">Warty
          Barberry</image-search></span></p>
    <p><span>(1)<image-search query="buxus sempervirens suffruticosa">English
          Boxwood</image-search></span></p>
    <p id="h.2rrrqc1"><span>(1)<image-search query="Buxus 'Green Mountain'">‘Green
          Mountain’ Boxwood</image-search></span></p>
    <p id="h.16x20ju"><span>(1)<image-search query="Buxus 'Green Beauty'">‘Green
          Beauty’ Boxwood</image-search></span></p>
    <p><span>(1)</span><span class="c0 c19">
        <image-search query="Buxus 'Wintergreen'">‘Wintergreen’
          Korean Boxwood</image-search>
      </span></p>
    <p><span>(1)<image-search query="dwarf camellia">Dwarf
          Camellia</image-search></span></p>
    <p><span>(1)<image-search query="daphne odora plant">Daphne</image-search></span><span></span>
    </p>
    <p><span>(1)</span><span class="c0 c19">
        <image-search query="chamaecyparis obtusa compacta fernspray">Compact
          Fernspray Falsecypress</image-search>
      </span></p>
    <p id="h.3qwpj7n"><span>(1)<image-search query="dwarf hinoki cypress">Dwarf
          Hinoki Falsecypress</image-search></span></p>
    <p><span>(1)<image-search query="distylium">Distylium</image-search></span>
    </p>
    <p id="h.261ztfg"><span>(1)<image-search query="chamaecyparis pisifera golden mop">Gold
          Mop Falsecypress</image-search></span></p>
    <p><span>(1)<image-search query="ilex crenata compacta">‘Compacta’
          Japanese Holly</image-search></span></p>
    <p><span>(1)<image-search query="ilex crenata 'Green Lustre'">‘Green
          Lustre’ Jap. Holly</image-search></span></p>
    <p><span>(1)<image-search query="dwarf burford holly">Dwarf
          Burford Holly</image-search></span></p>
    <p><span>(1)<image-search query="ilex glabra compacta">‘Compacta’
          Inkberry Holly</image-search></span></p>
    <p><span>(1)<image-search query="juniperus chinensis 'Nick's Compact'">‘Nick’s
          Compact’ Juniper</image-search></span></p>
    <p><span>(1)<image-search query="juniperus Sea Green">‘Sea
          Green’ Juniper’</image-search></span></p>
    <p><span>(1)<image-search query="juniperus virginiana 'Grey Owl'">‘Grey
          Owl’ Juniper’</image-search></span></p>
    <p id="h.l7a3n9"><span>(1)<image-search query="Otto Luyken Laurel">‘Otto
          Luyken’ Laurel</image-search></span></p>
    <p><span>(1)<image-search query="kalmia latifolia 'olympic fire'">‘Olympic
          Fire’ Mtn. Laurel</image-search></span></p>
    <p><span>(1)<image-search query="leatherleaf mahonia">Leatherleaf
          Mahonia</image-search></span></p>
    <p id="h.356xmb2"><span>(1)<image-search query="nandina domestica">Nandina</image-search></span><span>(4’-6’
        ht.) </span></p>
    <p id="h.1kc7wiv"><span>(1)<image-search query="‘Gulfstream” Nandina">‘Gulfstream’
          Nandina</image-search></span><span>3’-4’ </span></p>
    <p><span>(1)<image-search query="leucothoe fontanesiana">Leucothoe</image-search></span><span>
      </span></p>
    <p id="h.44bvf6o"><span>(1)<image-search query="loropetalum chinense">Loropetalum</image-search></span><span>
      </span></p>
    <p id="h.2jh5peh"><span>(1)<image-search query="mountain fire pieris">‘Mountain
          Fire’ Pieris</image-search></span></p>
    <p id="h.ymfzma"><span>(1)<image-search query="pinus mugo">Mugo
          Pine</image-search></span></p>
    <p id="h.3im3ia3"><span>(1)<image-search query="PJM Rhododendron">PJM
          Rhododendron</image-search></span></p>
    <p id="h.1xrdshw"><span>(1)<image-search query="Conoy Viburnum">‘Conoy’
          Viburnum</image-search></span></p>
    <p id="h.4hr1b5p"><span>(1)<image-search query="densiformis yew">Denisformis
          Yew</image-search></span></p>
    <p class="c3 c10"></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">SES (under 3’ ht.)</span><span class="c22"> </span><span>3Gal.
        containers @3’ O.C.</span></p>
    <p id="h.2wwbldi"><span>(1)<image-search query="‘Little Richard’ Abelia">‘Little
          Richard’ Abelia</image-search></span></p>
    <p id="h.1c1lvlb"><span>(1)<image-search query="Thuja occidentalis 'Hetz Midget'">‘Hetz
          Midget’ Arborvitae</image-search></span></p>
    <p><span>(1)<image-search query="thuja occidentalis 'Little Giant'">‘Little
          Giant’ Arborvitae</image-search></span></p>
    <p><span>(1)<image-search query="rhododendron 'Pink Gumpo'">‘Pink
          Gumpo’ Azalea</image-search></span></p>
    <p><span>(1)<image-search query="rhododendron 'White Gumpo'">‘White
          Gumpo’ Azalea</image-search></span></p>
    <p><span>(1)<image-search query="Dwarf Green Aucuba">Dwarf
          Green Aucuba</image-search></span></p>
    <p><span>(1)</span><span class="c0 c19">
        <image-search query="Buxus 'Morris Midget'">‘Morris
          Midget’ Boxwood</image-search>
      </span><span>18” </span></p>
    <p id="h.3w19e94"><span>(1)<image-search query="Buxus 'Green Gem'">‘Green
          Gem’ Boxwood</image-search></span><span>24”</span></p>
    <p id="h.2b6jogx"><span>(1)<image-search query="Buxus 'Green Velvet'">‘Green
          Velvet’ Boxwood</image-search></span><span>3’ </span></p>
    <p><span>(1)<image-search query="Winter Gem Boxwood">‘Winter
          Gem’ Boxwood</image-search></span><span>4’</span></p>
    <p id="h.qbtyoq"><span>(1)</span><span class="c0 c19">
        <image-search query="cedrus deodara prostrate beauty">'Prostrate
          Beauty' Deodar Cedar</image-search>
      </span></p>
    <p id="h.3abhhcj"><span>(1)<image-search query="dwarf japanese cedar">Dwarf
          Japanese Cedar</image-search></span></p>
    <p><span>(1)<image-search query="cotoneaster coral beauty">‘Coral
          Beauty’ Cotoneaster</image-search></span></p>
    <p><span>(1)<image-search query="dwarf golden mop threadleaf false cypress">Dwarf
          Gold Mop</image-search></span></p>
    <p><span>(1)</span><span class="c0 c19">
        <image-search query="chamaecyparis obtusa nana lutea">Golden
          Dwarf Hinoki Cypress</image-search>
      </span></p>
    <p><span>(1)<image-search query="hardy gardenia">Hardy
          Gardenia</image-search></span></p>
    <p><span>(1)<image-search query="heath plant">Heath/Heather</image-search></span>
    </p>
    <p><span>(1)<image-search query="helleri holly">‘Helleri’
          Holly</image-search></span></p>
    <p id="h.1pgrrkc"><span>(1)<image-search query="carissa holly">‘Carissa’
          Holly</image-search></span></p>
    <p><span>(1)<image-search query="ilex crenata 'Hoogendorn'">‘Hoogendoorn’
          Holly</image-search></span></p>
    <p><span>(1)<image-search query="ilex crenata 'Soft touch'">‘Soft
          Touch’ Holly</image-search></span></p>
    <p><span>(1)<image-search query="blue rug juniper">‘Blue
          Rug’ Juniper</image-search></span><span> 6” </span></p>
    <p><span>(1)<image-search query="Dwarf Garden Juniper">Dwarf
          Garden Juniper</image-search></span><span> 6” </span></p>
    <p><span>(1)<image-search query="Bar Harbor Juniper">Bar
          Harbor Juniper </image-search></span><span>12” </span></p>
    <p><span>(1)<image-search query="juniperus squamata 'Blue Star'">‘Blue
          Star’ Juniper</image-search></span><span>18”</span></p>
    <p><span>(1)<image-search query="andorra juniper">Andorra
          Juniper</image-search></span><span>(18” ht.)</span></p>
    <p><span>(1)<image-search query="Green Sargent Juniper">Sargent
          Juniper</image-search></span><span>(24” ht.) </span></p>
    <p><span>(1)<image-search query="juniperus 'Parsonii'">‘Parsonii’
          Juniper</image-search></span><span>(3’ ht.)</span></p>
    <p><span>(1)<image-search query="Old Gold Juniper">‘Old
          Gold’ Juniper</image-search></span></p>
    <p id="h.49gfa85"><span>(1)<image-search query="prunus laurocerasus chestnut hill">Dwarf
          Cherry Laurel</image-search></span></p>
    <p><span>(1)<image-search query="kalmia latifolia 'Elf'">‘Elf’
          Mountain Laurel</image-search></span></p>
    <p><span>(1)<image-search query="loropetalum 'Snow Muffin'">‘Snow
          Muffin’ Loropetalum</image-search></span></p>
    <p id="h.2olpkfy"><span>(1)<image-search query="nandina firepower">‘Firepower’
          Nandina</image-search></span></p>
    <p><span>(1)<image-search query="nandina harbor dwarf">‘Harbor
          Dwarf’ Nandina</image-search></span></p>
    <p><span>(1)<image-search query="pieris japonica 'little heath'">Dwarf
          Variegated Pieris</image-search></span></p>
    <p><span>(1)<image-search query="dwarf pieris varieties">Dwarf
          Pieris</image-search></span></p>
    <p><span>(1)<image-search query="Pyracantha coccinea Lowboy">Pyracantha
          Lowboy</image-search></span></p>
    <p><span>(1)<image-search query="Sarcococca hookerana">Sweetbox</image-search></span><span></span>
    </p>
    <p><span>(1)<image-search query="globosa dwarf colorado spruce">Dwarf
          Colorado Blue Spruce</image-search></span></p>
    <p><span>(1)<image-search query="bird's nest spruce">Bird’s
          Nest Spruce</image-search></span></p>
    <p><span>(1)<image-search query="taxus repandens">‘Repandens’
          Yew</image-search></span></p>
    <p id="h.13qzunr"><span>(1)<image-search query="plum yew shrub">Plum
          Yew</image-search></span></p>
    <p><span>(1)<image-search query="yucca filamentosa">Yucca</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="yucca filamentosa golden sword">‘Golden
          Sword’ Yucca</image-search></span></p>
    <p class="c3 c10"><span class="c18 c24"></span></p>
    <p><span class="c18">GROUNDCOVERS</span></p>
    <p id="h.3nqndbk"><span class="c16">GCS</span><span class="c4">
        <image-search query="groundcovers for sun">Groundcovers
          for Sun</image-search>
        <image-search query="groundcovers for shade">Groundcovers
          for Shade</image-search>
      </span></p>
    <p id="h.22vxnjd"><span><a href="http://www.gardenguides.com/96694-rid-english-ivy-lawn.html">Remove
          English Ivy</a> <a href="http://www.landscapesupplyva.com/">Landscape
          Supply Roanoke</a>
        <image-search query="bush hog">Bushhogged</image-search>
        <image-search query="rip rap ditch">Riprap</image-search>
      </span>
    </p>
    <p id="h.i17xr6"><span>All remaining sunny areas to be seeded with <image-search query="hard fescue grass">Hard
          Fescue</image-search>. <a href="http://www.scotts.com/smg/brand/roundup/brandLanding.jsp">Roundup</a>areas
        to be seeded in late summer. Apply seed in September/October and irrigate lightly 3times/day for 2
        weeks or until seed germinates (never let the ground dry out), then deeper soaking once/day for 4 more
        weeks. Use a <image-search query="Weedeater">Weedeater</image-search>to
        control weeds for 2 to 3 years until Fescue thickens up. Do not mow the Hard Fescue any lower than
        6” as this will kill it. <image-search query="Moss Garden">Moss
          Garden</image-search>
        <image-search query="Moss Milkshakes">Moss
          Milkshakes</image-search><a
          href="http://www.deq.virginia.gov/Portals/0/DEQ/Water/StormwaterManagement/Erosion_Sediment_Control_Handbook/Chapter3_3.31.pdf">Temporary
          Seeding</a>
        <image-search query="Wildflower Mix for virginia">Wildflower
          Mix</image-search>Wild Mustard
      </span>
    </p>
    <p id="h.320vgez"><span>
        <image-search query="black eyed susan wildflower seeds">Black-Eyed-Susan</image-search>
        <image-search query="queen anne's lace seeds">Queen
          Anne's Lace</image-search>
        <image-search query="yarrow seeds">Yarrow</image-search>
        <image-search query="Crown Vetch">Crown
          Vetch</image-search>
        <image-search query="Lespedeza">Lespedeza</image-search>
      </span><span>
        <image-search query="rock garden ideas">Rock
          Garden</image-search>
      </span></p>
    <p><span>For shady areas try <image-search query="ajuga reptans">Ajuga</image-search>
        <image-search query="bishop's weed">Bishop’s
          Weed</image-search>
        <image-search query="Creeping Jenny">Creeping
          Jenny</image-search>
        <image-search query="Lily–of-the-Valley">Lily–of-the-Valley</image-search>
        <image-search query="Mondo Grass">Mondo
          Grass</image-search>
        <image-search query="Pachysandra">Pachysandra</image-search>
        <image-search query="sweet woodruff">Sweet
          Woodruff</image-search>
        <image-search query="Vinca">Vinca</image-search>etc.
        Install 2.5” pots cell packs flats @8” O.C. (1)Total in staggered rows @ 30”
        O.C.
      </span></p>
    <p><span>(1)<image-search query="ceratostigma plumbaginoides">Ceratostigma</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="cerastium tomentosum">Cerastium</image-search></span><span></span>
    </p>
    <p><span>(1)<image-search query="Common orange daylily">Daylily,
          Common Orange</image-search></span></p>
    <p><span>(1)<image-search query="hard fescue grass">‘Aurora
          Gold’ Hard Fescue</image-search></span></p>
    <p id="h.1h65qms"><span>(1)<image-search query="carex everillo">Carex
          Everillo Series</image-search></span></p>
    <p class="c3 c10"></p>
    <p><span>(1)<image-search query="Creeping Germander">Creeping
          Germander</image-search></span></p>
    <p><span>(1)<image-search query="hypericum calycinum">Hypericum</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="liriope muscari big blue">Liriope
          muscari</image-search></span></p>
    <p><span>(1)<image-search query="liriope spicata">Liriope
          spicata</image-search></span></p>
    <p><span>(1)<image-search query="mentha spicata">Mint</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="oregano plant">Oregano</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="creeping phlox">Phlox,
          Creeping</image-search></span></p>
    <p><span>(1)<image-search query="strawberry plant">Strawberry</image-search></span><span></span>
    </p>
    <p><span class="c16">GCH</span><span class="c22">
        <image-search query="groundcovers for shade">Groundcovers
          For Shade</image-search>
      </span></p>
    <p id="h.415t9al"><span>(1)<image-search query="ajuga reptans">Ajuga</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="bishop's weed">Bishop’s
          Weed</image-search></span></p>
    <p><span>(1)<image-search query="Creeping Jenny">Creeping
          Jenny</image-search></span></p>
    <p><span>(1)<image-search query="hedera helix">English
          Ivy</image-search></span></p>
    <p><span>(1)<image-search query="epimedium">Epimedium</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="ferns">Ferns</image-search></span>
    </p>
    <p><span>
        <image-search query="mexican feather grass">Japanese
          Stiltgrass</image-search>
      </span><span>(annual)</span></p>
    <p><span>(1)<image-search query="lamium maculatum">Lamium</image-search></span><span>
      </span></p>
    <p id="h.2gb3jie"><span>(1)<image-search query="Lily–of-the-Valley">Lily–of-the-Valley</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="wintercreeper euonymus fortunei">Euonymus,
          Wintercreeper</image-search></span></p>
    <p><span>(1)<image-search query="Houttouynia">Houttouynia</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="lamiastrum galeobdolon">Lamiastrum</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="spicata liriope">Liriope,
          Spicata</image-search></span></p>
    <p><span>(1)<image-search query="Mondo Grass">Mondo
          Grass</image-search></span></p>
    <p id="h.vgdtq7"><span>(1)<image-search query="moss plant">Moss</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Pachysandra">Pachysandra</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="sweet woodruff">Sweet
          Woodruff</image-search></span></p>
    <p id="h.3fg1ce0"><span>(1)<image-search query="Vinca">Vinca</image-search></span><span></span>
    </p>
    <p><span>(1)<image-search query="vinca major vine">Bigleaf
          Vinca</image-search></span></p>
    <p class="c3 c10"><span class="c18 c24"></span></p>
    <p><span class="c18">PERENNIALS</span></p>
    <p><span>Place Large plants at back or center of bed @ +-30”O.C., Medium Plants in middle of bed @
        +-24” O.C., Small Plants in edge of bed @12”-18” O.C. Plant bulbs between plants in
        random clumps of 12 in middle of bed. Scatter smaller bulbs randomly near edge. </span><span>
        <image-search query="perennial beds">Perennial
          Beds</image-search>
        <image-search query="french parterre gardens">French
          Parterre Style Gardens</image-search>
        <image-search query="flower beds">Flower
          Beds</image-search>
      </span></p>
    <p><span>
        <image-search query="deer resistant perennials">Deer
          Resistant Perennials</image-search>
        <image-search query="low maintenance perennials">Low
          Maintenance Perennials</image-search>
        <image-search query="perennials for shade">Perennials
          for Shade</image-search>
        <image-search query="Perennials for Sun">Perennials
          for Sun</image-search>
      </span></p>
    <p><span><a href="http://www.smithmtn.com/ShorelineMgmt/Plan/Appendix_F_VDCR_Native_Plants.pdf">AEP
          Approved perennials</a><a
          href="http://www.riverbendnursery.com/index.cfm/fuseaction/plants.main/index.htm">Riverbend
          Nursery</a>for perennials <a href="http://www.kvbwholesale.com/">Van
          Bourgondien &amp; Sons</a></span><span>for Bulbs ) </span></p>
    <p id="h.1ulbmlt"><span>For sunny beds try <image-search query="Aster">Aster</image-search>
        <image-search query="coreopsis">Coreopsis</image-search>
        <image-search query="siberian iris">Iris</image-search>
      </span><span>
        <image-search query="Kniphofia">Red
          Hot Poker</image-search>
        <image-search query="yarrow flower">Yarrow</image-search>
        <image-search query="geranium 'Johnson's Blue'">Geranium
          ‘Johnson’s Blue’</image-search>
        <image-search query="Black-eyed-susan">Black-eyed-susan</image-search>
        <image-search query="Sedums">Sedums</image-search>
        <image-search query="dianthus gratianopolitanus">Dianthus</image-search>
        <image-search query="candytuft">Canydtuft</image-search>
        <image-search query="rosemary">Rosemary</image-search>
        <image-search query="lavender">Lavender</image-search>
        <image-search query="woodland perennials">Woodland
          Perennials</image-search>
      </span></p>
    <p><span class="c16">LPS</span></p>
    <p><span>(1)<image-search query="eupatorium coelestinum">Ageratum</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Tall Amsonia">Amsonia</image-search></span>
    </p>
    <p><span>(1)<image-search query="tall aster plant">Aster</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="bear’s breeches acanthus mollis">Bear’s
          Breeches</image-search></span></p>
    <p><span>(1)<image-search query="Tall Bellflower">Bellflower</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Blackberry Lily">Blackberry
          Lily</image-search></span></p>
    <p><span>(1)<image-search query="Tall Boltonia">Boltonia</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="butterfly weed plant">Butterfly
          Weed</image-search></span></p>
    <p><span>(1)<image-search query="buttonbush">Buttonbush</image-search></span>
    </p>
    <p><span>(1)<image-search query="Crocosmia">Crocosmia</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="daylily">Daylily</image-search></span><span></span>
    </p>
    <p><span>(1)<image-search query="dame's rocket">Dames
          Rocket</image-search></span></p>
    <p id="h.4ekz59m"><span>(1)<image-search query="echinacea purpurea">Echinacea</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="echinops ritro">Echinops</image-search></span>
    </p>
    <p><span>(1)<image-search query="tall hibiscus plants">Hibiscus</image-search></span>
    </p>
    <p><span>(1)<image-search query="Indigo blue false">Indigo,
          Blue False</image-search></span></p>
    <p><span>(1)<image-search query="iris ensata">Japanese
          Water Iris</image-search></span></p>
    <p><span>(1)<image-search query="Iris">Iris</image-search></span>
    </p>
    <p id="h.2tq9fhf"><span>(1)<image-search query="Russian Sage">Russian
          Sage</image-search></span></p>
    <p><span>(1)<image-search query="perennial salvias">Salvia</image-search></span>
    </p>
    <p id="h.18vjpp8"><span>(1)<image-search query="siberian iris">Siberian
          Iris</image-search></span></p>
    <p><span>(1)<image-search query="iris tectorum">Iris,
          Roof</image-search></span></p>
    <p><span>(1)<image-search query="iris tectorum 'Variegata'">Iris,
          Roof , Variegated</image-search></span></p>
    <p><span>(1)<image-search query="liatris spicata kobold">Liatris
          ‘Kobold’</image-search></span></p>
    <p id="h.3sv78d1"><span>(1)<image-search query="peony flowers">Peony</image-search></span>
    </p>
    <p id="h.280hiku"><span>(1)<image-search query="Kniphofia">Red
          Hot Poker</image-search></span></p>
    <p><span>(1)<image-search query="wormwood plant">Wormwood</image-search></span>
    </p>
    <p><span>(1)<image-search query="yarrow flower">Yarrow</image-search></span>
    </p>
    <p><span>(1) <image-search query="hay scented fern">Hay-Scented
          Fern</image-search></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">MPS</span><span class="c17 c15"> </span></p>
    <p id="h.n5rssn"><span>(1)<image-search query="aster">Aster</image-search></span><span></span>
    </p>
    <p><span>(1)<image-search query="Aster ‘Lady in Black'">Aster
          ‘Lady in Black'</image-search></span></p>
    <p><span>(1)Aster ‘Fanny'</span></p>
    <p><span>(1)<image-search query="Aster ‘Purple Dome'">Aster
          ‘Purple Dome'</image-search></span></p>
    <p><span>(1)<image-search query="Aster ‘October Skies'">Aster
          ‘October Skies'</image-search></span></p>
    <p><span>(1)<image-search query="Aster ‘Whitewood'">Aster
          ‘Whitewood'</image-search></span></p>
    <p><span>(1)<image-search query="Coreopsis ‘Moonbeam’">Coreopsis
          ‘Moonbeam’</image-search></span></p>
    <p id="h.375fbgg"><span>(1)<image-search query="shasta daisy">Shasta
          Daisy</image-search></span></p>
    <p><span>(1)<image-search query="Daylily">Daylily</image-search></span>
    </p>
    <p id="h.1maplo9"><span>(1)<image-search query="Black-eyed-susan">Black-eyed-Susan</image-search></span>
    </p>
    <p><span>(1)<image-search query="gaura">Gaura</image-search></span>
    </p>
    <p><span>(1)<image-search query="geranium 'Johnson's Blue'">Geranium
          ‘Johnson’s Blue’</image-search></span></p>
    <p id="h.46ad4c2"><span>(1)<image-search query="Sedum’Autumn Joy’">Sedum’Autumn
          Joy’</image-search></span></p>
    <p id="h.2lfnejv"><span>(1)<image-search query="lavender">Lavender</image-search></span>
    </p>
    <p id="h.10kxoro"><span>(1)<image-search query="monarda">Bee
          Balm</image-search></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">SPS</span><span class="c17 c15"> </span></p>
    <p><span>(1)<image-search query="dwarf aster varieties">Dwarf
          Aster</image-search></span></p>
    <p><span>(1)<image-search query="Aster ‘Royal Opal'">Aster
          ‘Royal Opal'</image-search></span></p>
    <p><span>(1)<image-search query="Aster ‘Peter Harrison'">Aster
          ‘Peter Harrison'</image-search></span></p>
    <p><span>(1)<image-search query="Aster ‘Snow Flurry'">Aster
          ‘Snow Flurry'</image-search></span></p>
    <p id="h.3kkl7fh"><span>(1)<image-search query="dianthus gratianopolitanus">Dianthus</image-search></span><span>
      </span></p>
    <p id="h.1zpvhna"><span>(1)<image-search query="candytuft">Canydtuft</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="rosemary">Rosemary</image-search></span>
    </p>
    <p id="h.4jpj0b3"><span>(1)<image-search query="heaths and heathers">Heaths
          &amp; Heathers</image-search></span></p>
    <p id="h.2yutaiw"><span>(1)<image-search query="Coreopsis auriculata 'Nana'">Coreopsis,
          Dwarf</image-search></span></p>
    <p><span>(1)<image-search query="phlox subulata">Creeping
          Phlox</image-search></span></p>
    <p><span>(1)<image-search query="geranium cinerium 'Ballerina'">Geranium
          ‘Ballerina’</image-search></span></p>
    <p><span>(1)<image-search query="saponaria ocymoides">Rock
          Soapwort</image-search></span></p>
    <p><span>(1)<image-search query="sedum ground cover">Sedum</image-search></span>
    </p>
    <p id="h.1e03kqp"><span>(1)<image-search query="Creeping Jenny">Creeping
          Jenny</image-search></span></p>
    <p id="h.3xzr3ei"><span>For shady flower beds try <image-search query="ferns">Ferns</image-search>
        <image-search query="ajuga reptans">Ajuga</image-search>
        <image-search query="sweet woodruff">Sweet
          Woodruff</image-search>
        <image-search query="Lily–of-the-Valley">Lily–of-the-Valley</image-search>
        <image-search query="solomon’s seal">Solomon’s
          Seal</image-search>
        <image-search query="Ostrich Fern">Ostrich
          Fern</image-search>
        <image-search query="astilbe flower">Astilbe</image-search>
        <image-search query="bleeding heart flower">Bleeding
          Heart</image-search>
        <image-search query="columbine flower">Columbine</image-search>
        <image-search query="coral bells flower">Coral
          Bells</image-search>
        <image-search query="helleborus">Helleborus</image-search>
        <image-search query="liriope muscari">Liriope</image-search>
        <image-search query="iris cristata">Crested
          Iris</image-search>
        <image-search query="Christmas Fern">Christmas
          Fern</image-search>
      </span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">LPH</span></p>
    <p id="h.2d51dmb"><span>(1)<image-search query="anemone plant">Anemone</image-search></span>
    </p>
    <p><span>(1)<image-search query="cimicifuga racemosa">Bugbane</image-search></span>
    </p>
    <p><span>(1)<image-search query="Ostrich Fern">Ostrich
          Fern</image-search></span><span>(moist)</span></p>
    <p id="h.sabnu4"><span>(1)<image-search query="shield fern">Shield
          Fern</image-search></span></p>
    <p><span>(1)<image-search query="cinnamon fern">Cinnamon
          Fern</image-search></span><span>(moist)</span></p>
    <p><span>(1)<image-search query="begonia grandis hardy begonia">Hardy
          Begonia</image-search></span></p>
    <p><span>(1)<image-search query="large hosta varieties">Hosta</image-search></span>
    </p>
    <p><span>(1)<image-search query="ligularia plant">Ligularia</image-search></span><span>(moist)</span>
    </p>
    <p><span>(1)<image-search query="monkshood plant">Monkshood</image-search></span>
    </p>
    <p><span>(1)<image-search query="Royal Fern">Royal
          Fern</image-search></span><span>(moist)</span></p>
    <p id="h.3c9z6hx"><span>(1)<image-search query="solomon’s seal">Solomon’s
          Seal</image-search></span></p>
    <p class="c3 c10"></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p class="c25" id="h.1rf9gpq"><span class="c16">MPH</span><span class="c22"></span><span>
        <image-search query="astilbe flower">Astilbe</image-search>
      </span><span>
        <image-search query="bleeding heart flower">Bleeding
          Heart</image-search>
      </span><span>
      </span></p>
    <p id="h.4bewzdj"><span>(1)<image-search query="astilbe flower">Astilbe</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="bleeding heart flower">Bleeding
          Heart</image-search></span></p>
    <p><span>(1)<image-search query="brunnera macrophylla">Brunnera</image-search></span><span>
      </span></p>
    <p id="h.2qk79lc"><span>(1)<image-search query="columbine flower">Columbine</image-search></span><span>
      </span></p>
    <p id="h.15phjt5"><span>(1)<image-search query="coral bells flower">Coral
          Bells</image-search></span></p>
    <p id="h.3pp52gy"><span>(1)<image-search query="helleborus">Helleborus</image-search>
      </span><span class="c0 c14 c21 c38">
        <image-search query="how to naturalize hellebore">(allow
          to naturalize)</image-search>
      </span></p>
    <p><span>(1)<image-search query="hostas">Hosta</image-search></span>
    </p>
    <p id="h.24ufcor"><span>(1)<image-search query="tassel fern">Tassel
          Fern</image-search></span></p>
    <p><span>(1)<image-search query="liriope muscari">Green
          Liriope Muscari</image-search></span></p>
    <p><span>(1)<image-search query="variegated liriope">Variegated
          Liriope Muscari</image-search></span></p>
    <p id="h.jzpmwk"><span>(1)<image-search query="Christmas Fern">Christmas
          Fern</image-search></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">SPH</span></p>
    <p><span>(1)<image-search query="Maidenhair Fern">Maidenhair
          Fern</image-search></span></p>
    <p><span>(1)<image-search query="asarum">Ginger</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Japanese Painted Fern">Japanese
          Painted Fern</image-search></span></p>
    <p><span>(1)<image-search query="bergenia cordifolia">Bergenia</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="autumn fern">Autumn
          Fern</image-search></span></p>
    <p><span>(1)<image-search query="Galax">Galax</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="gaultheria procumbens">Wintergreen</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="dwarf hosta plants">Dwarf
          Hosta</image-search>
        <image-search query="Hosta 'Brim Cup'">'Brim
          Cup'</image-search>
      </span></p>
    <p><span>
        <image-search query="Hosta 'Pandora's Box'">'Pandora's
          Box'</image-search>
        <image-search query="Hosta 'Dorset Blue'">‘Dorset
          Blue’</image-search>
      </span></p>
    <p><span>
        <image-search query="hosta 'gold drop'">'Gold
          Drop'</image-search>'Golden Tiara'
      </span></p>
    <p><span>
        <image-search query="Hosta 'Kabitan'">'Kabitan'</image-search>
      </span><span>
      </span></p>
    <p><span>(1)<image-search query="iris cristata">Crested
          Iris</image-search></span></p>
    <p><span>(1)<image-search query="Liriope muscari 'Silver Midget'">Liriope
          ‘Silver Midget’</image-search></span></p>
    <p><span>(1)<image-search query="Lungwort">Lungwort</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Bloodroot">Bloodroot</image-search></span>
    </p>
    <p><span>(1)<image-search query="sedum ground cover">Sedum</image-search></span>
    </p>
    <p><span class="c18">BULBS</span><span class="c22 c37"> </span><span class="c26">*Owner to pick
        variety. Go to <a href="http://www.riverbendnursery.com/index.cfm/fuseaction/plants.main/index.htm">Riverbend
          Nursery</a>for perennials or <a href="http://www.kvbwholesale.com/">Van
          Bourgondien &amp; Sons</a>for Bulbs. Plant small bulbs toward front of bed in
        random groupings. Plant larger bulbs in clumps of 12 to 15 in middle and back of bed. </span><span
        class="c17 c15"></span></p>
    <p><span>(1)<image-search query="hardy begonia">Hardy
          Begonia</image-search></span></p>
    <p><span>(1)<image-search query="chionodoxa">Chionodoxa</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Colchium">Colchium</image-search></span><span>
      </span></p>
    <p id="h.33zd5kd"><span>(1)<image-search query="Crocus">Crocus</image-search></span><span></span>
    </p>
    <p><span>(1)<image-search query="Hardy Cyclamen">Cyclamen</image-search></span>
    </p>
    <p id="h.1j4nfs6"><span>(1)<image-search query="daffodil flower">Daffodil</image-search></span><span>
        <image-search query="Daffodil ‘Artic Gold’">‘Artic
          Gold’</image-search>
      </span></p>
    <p><span>
        <image-search query="Daffodil ‘Bella Vista’">‘Bella
          Vista’</image-search>
        <image-search query="Daffodil ‘Erlicheer’">‘Erlicheer’</image-search>
      </span><span>
      </span></p>
    <p><span>
        <image-search query="Daffodil ‘February Gold’">‘February
          Gold’</image-search>
      </span></p>
    <p><span>(1)<image-search query="Dogtooth Violet">Dogtooth
          Violet</image-search></span></p>
    <p><span>(1)<image-search query="Dutch Iris">Dutch
          Iris</image-search></span></p>
    <p><span>(1)<image-search query="Eranthis">Eranthis</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Eremurus">Eremurus</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Fritillaria">Fritillaria</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Galanthus">Galanthus</image-search></span><span>
      </span></p>
    <p id="h.434ayfz"><span>(1)<image-search query="Grape Hyacinth">Grape
          Hyacinth </image-search></span></p>
    <p><span>(1)<image-search query="Hyacinth">Hyacinth</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Leucojum">Leucojum</image-search></span><span>
      </span></p>
    <p id="h.2i9l8ns"><span>(1)<image-search query="Resurrection Lily">Resurrection
          Lily</image-search></span></p>
    <p id="h.xevivl"><span>(1)<image-search query="scilla siberica">Scilla</image-search></span>
    </p>
    <p id="h.3hej1je"><span>(1)<image-search query="tulip">Tulip</image-search></span>
    </p>
    <p><span>(1)<image-search query="Windflower">Windflower</image-search></span>
    </p>
    <p id="h.1wjtbr7"><span>(1)<image-search query="wood hyacinth">Wood
          Hyacinth</image-search></span></p>
    <p class="c3 c10"></p>
    <p class="c3 c10"></p>
    <p class="c3 c10"><span class="c18 c24"></span></p>
    <p><span class="c18">ORNAMENTAL GRASS</span></p>
    <p><span class="c16">LOG</span></p>
    <p><span>(1)<image-search query="calamagrostis x acutiflora 'karl foerster'">Feather
          Reed Grass ‘Karl Foerster’</image-search></span></p>
    <p id="h.4gjguf0"><span>(1)<image-search query="miscanthus sinensis 'gracillimus'">Maiden
          Grass</image-search></span></p>
    <p><span>(1)<image-search query="miscanthus sinensis variegatus">Maiden
          Grass, Variegated</image-search></span></p>
    <p><span>(1)<image-search query="cortaderia selloana">Pampas
          Grass</image-search></span></p>
    <p><span>(1)<image-search query="arundinacea 'Skyracer'">Moor
          Grass ’Skyracer’</image-search></span></p>
    <p><span>(1)<image-search query="miscanthus sinensis strictus">Porcupine
          Grass</image-search></span></p>
    <p><span>(1)<image-search query="saccharum ravennae">Plume
          Grass</image-search></span></p>
    <p><span>(1)<image-search query="phalaris arundinacea">Ribbon
          Grass</image-search></span></p>
    <p><span>(1)<image-search query="miscanthus sinensis zebrinus">Zebra
          Grass</image-search></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">MOG</span></p>
    <p id="h.2vor4mt"><span>(1)<image-search query="Blue Oat Grass “Sapphire”">’Sapphire’
          Blue Oat Grass </image-search></span></p>
    <p><span>(1)<image-search query="Beard Grass">Broom
          Sedge</image-search></span></p>
    <p id="h.1au1eum"><span>(1)<image-search query="imperata red baron">’Red
          Baron’ Blood Grass</image-search></span></p>
    <p><span class="c19">(1)</span><span class="c0 c19 c38">
        <image-search query="pennisetum alopecuroides 'hameln'">’Hameln’
          Dwarf Fountain Gras</image-search>
      </span><span class="c0 c19">
        <image-search query="pennisetum alopecuroides 'hameln'">s</image-search>
      </span><span class="c15 c5 c19"> </span></p>
    <p id="h.3utoxif"><span class="c19">(1)</span><span class="c0 c19">
        <image-search query="pennisetum alopecuroides moudry">’Mouldry’
          Black Fountain Grass</image-search>
      </span><span class="c15 c5 c19"> </span></p>
    <p id="h.29yz7q8"><span>(1)<image-search query="Hakon Grass">Hakon
          Grass</image-search></span><span>(shade) </span></p>
    <p><span>(1)<image-search query="Tufted Hair Grass">Tufted
          Hair Grass</image-search></span></p>
    <p><span>(1)<image-search query="panicum virgatum 'shenandoah'">Switch
          Grass</image-search></span></p>
    <p id="h.p49hy1"><span>(1)<image-search query="Dwarf Zebra Grass">Dwarf
          Zebra Grass</image-search></span></p>
    <p><span>(1)<image-search query="mexican feather grass">Mexican
          Feather Grass</image-search></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">SOG</span></p>
    <p><span>(1)<image-search query="Acorus Sweet Flag">Acorus
          Sweet Flag</image-search></span><span class="c14">(bog plant)</span><span>
      </span></p>
    <p><span>(1)<image-search query="carex kaga nishiki">Carex
          ‘Kaga Nishiki’</image-search></span><span> shade</span></p>
    <p id="h.393x0lu"><span>(1)<image-search query="carex flacca">Carex,
          Blue</image-search></span></p>
    <p id="h.1o97atn"><span>(1)<image-search query="festuca glauca 'elijah blue'">‘Elijah
          Blue’ Fescue</image-search></span></p>
    <p id="h.488uthg"><span>(1)<image-search query="festuca beyond blue">Festuca
          ‘Beyond Blue’</image-search></span></p>
    <p id="h.2ne53p9"><span class="c19">(1)</span><span class="c0 c19">
        <image-search query="pennisetum alopecuroides 'little honey'">’Little
          Honey’ Fountain Grass</image-search>
      </span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">WATER</span><span class="c22">
        <image-search query="bog plants">Bog
          plants</image-search>
      </span></p>
    <p><span>(1)<image-search query="Acorus Sweet Flag">Acorus
          Sweet Flag</image-search>(bog plant) <image-search query="horsetail plant">Horsetail</image-search>
        <image-search query="cattails">Cattails</image-search>
        <image-search query="water iris for ponds">Water
          Iris</image-search>
        <image-search query="anacharis plant">Anacharis</image-search>
        <image-search query="parrot’s feather">Parrot’s
          Feather</image-search>
        <image-search query="thalia plant">Thalia</image-search>
        <image-search query="arrowhead water plant">Arrowhead</image-search>
        <image-search query="Papyrus plant">Papyrus</image-search>
        <image-search query="taro plant">Taro</image-search>
        <image-search query="juncus effusus">Rush</image-search>
        <image-search query="water lilies">Water
          Lilies</image-search>
        <image-search query="tropical water lilies">Tropical
          Water Lilies</image-search>
        <image-search query="lotus plant">Lotus</image-search>
        <image-search query="Water Hyacinth">Water
          Hyacinth</image-search>
        <image-search query="water lettuce plant">Water
          Lettuce</image-search>
      </span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">HERBS</span><span class="c22">
        <image-search query="herb garden">Herb
          Garden</image-search>
        <image-search query="Bay Laurel">Bay
          Laurel</image-search>
        <image-search query="Calamint">Calamint</image-search>
        <image-search query="catmint">Catmint</image-search>
        <image-search query="Chamomile">Chamomile</image-search>
        <image-search query="chives">Chives</image-search>
        <image-search query="French Tarragon">French
          Tarragon</image-search>
        <image-search query="Germander">Germander</image-search>
        <image-search query="Lemon Verbena">Lemon
          Verbena</image-search>
        <image-search query="Lavender">Lavender</image-search>
        <image-search query="Lavender Cotton">Lavender
          Cotton</image-search>
        <image-search query="Lemon Balm">Lemon
          Balm</image-search>
        <image-search query="Oregano">Oregano</image-search>
        <image-search query="peppermint">Peppermint</image-search>
        <image-search query="rosemary herb">Rosemary</image-search>
        <image-search query="rue plant">Rue</image-search>
        <image-search query="sage plant">Sage</image-search>
        <image-search query="savory herb">Savory</image-search>
        <image-search query="thyme herb">Thyme</image-search>etc.
        (All these plants grown by <a href="http://www.riverbendnursery.com/">Riverbend
          Nursey</a>
      </span><span>in Riner, Va.)</span></p>
    <p><span>(1)<image-search query="chives">Chives</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Lemon Verbena">Lemon
          Verbena</image-search></span></p>
    <p><span>(1)<image-search query="French Tarragon">French
          Tarragon</image-search></span></p>
    <p><span>(1)<image-search query="Calamint">Calamint</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Chamomile">Chamomile</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Bay Laurel">Bay
          Laurel</image-search></span></p>
    <p><span>(1)<image-search query="Lavender">Lavender</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Lemon Balm">Lemon
          Balm</image-search></span></p>
    <p id="h.12jfdx2"><span>(1)<image-search query="Catnip">Catnip</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Oregano">Oregano</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="rosemary herb">Rosemary</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="rue plant">Rue</image-search></span><span>
      </span></p>
    <p id="h.3mj2wkv"><span>(1)<image-search query="sage plant">Sage</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Lavender Cotton">Lavender
          Cotton</image-search></span></p>
    <p><span>(1)<image-search query="savory herb">Savory</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Germander">Germander</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="thyme herb">Thyme</image-search></span>
    </p>
    <p><span>(1)<image-search query="Apple Mint">Apple
          Mint</image-search></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">FRUIT &amp; NUT TREES</span><span class="c17 c15"> </span></p>
    <p id="h.21od6so"><span>(1)<image-search query="Pecan Tree">Pecan
          Tree</image-search></span></p>
    <p><span>(1)<image-search query="hickory nut trees for sale">Hickory
          Nut Tree</image-search></span></p>
    <p><span>(1)Walnut Tree</span></p>
    <p id="h.gtnh0h"><span>(1)<image-search query="hardy fig tree">Fig
          tree</image-search></span></p>
    <p id="h.30tazoa"><span>(1)<image-search query="Bing Cherry trees">Bing
          Cherry</image-search></span></p>
    <p id="h.1fyl9w3"><span>(1)<image-search query="Dwarf Apple trees">Dwarf
          Apple</image-search></span></p>
    <p><span>(1)<image-search query="Dwarf Apple tree espalier">Dwarf
          Apple tree espalier</image-search></span></p>
    <p id="h.3zy8sjw"><span>(1)<image-search query="plum tree">Plum
          Tree</image-search></span></p>
    <p><span>(1)<image-search query="Pecan Tree">Pecan
          Tree</image-search></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p id="h.2f3j2rp"><span class="c16">BERRIES &amp; BRAMBLES</span><span class="c4">
        <image-search query="blueberries shrubs">Blueberries</image-search>
        <image-search query="raspberry vines">Raspberries</image-search>
        <image-search query="Blackberries plants">Blackberries</image-search>
        <image-search query="Wineberry plants">Wineberries</image-search>
      </span>
    </p>
    <p><span>(1)<image-search query="blueberries shrubs">Blueberries</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="raspberry vines">Raspberries</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Blackberries plants">Blackberries</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Wineberry plants">Wineberries</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="strawberry plant">Strawberry</image-search></span><span>
      </span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p class="c3 c10"><span class="c13"></span></p>
    <p><span class="c16">BIENNIALS AND RESEEDING ANNUALS</span></p>
    <p><span>(1)<image-search query="four o’clocks">Four
          O’Clock</image-search></span></p>
    <p><span>(1)<image-search query="foxglove plant">Foxglove</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="gloriosa daisy">Gloriosa
          Daisy</image-search></span></p>
    <p><span>(1)<image-search query="hollyhocks">Hollyhocks</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="larkspur flower">Larkspur</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="lunaria annua">Money
          Plant</image-search></span></p>
    <p><span>(1)<image-search query="Annual Poppy">Poppy</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="spider flower">Spider
          Flower</image-search></span><span> </span></p>
    <p class="c3 c10"><span class="c18 c24"></span></p>
    <p><span class="c18">ANNUALS</span><span class="c22">
        <image-search query="Seasonal Annuals">Seasonal
          Annuals</image-search>
        <image-search query="deer resistant annuals">Deer
          Resistant Annuals</image-search>
        <image-search query="hanging ferns">Hanging
          Ferns</image-search>
      </span></p>
    <p><span>Seasonal Annuals Ex. <image-search query="Pansy">Pansies</image-search>&amp;
        <image-search query="Ornamental Kale">Ornamental
          Kale</image-search>from late fall to spring &amp; <image-search query="Petunia">Petunia</image-search>
      </span><span>
        <image-search query="Annual Geranium">Geranium</image-search>
      </span><span>
        <image-search query="Impatiens">Impatiens</image-search>etc.
        through summer and fall. Landscape Contractor to provide 10” of <image-search query="topsoil">Topsoil
        </image-search>
      </span><span>as
        part of this contract. Owner to negotiate separate price w/Landscape Contractor
        for appropriate seasonal annuals. </span></p>
    <p><span>(1)<image-search query="ageratum">Ageratum</image-search></span><span>
      </span></p>
    <p id="h.u8tczi"><span>(1)<image-search query="Wax Begonia">Wax
          Begonia</image-search></span></p>
    <p><span>(1)<image-search query="Ornamental Cabbage">Ornamental
          Cabbage</image-search></span></p>
    <p><span>(1)<image-search query="Coleus">Coleus</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="cosmos flower pictures">Cosmos</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="dusty miller">Dusty
          Miller</image-search></span></p>
    <p><span>(1)<image-search query="Annual Geranium">Geranium</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Impatiens">Impatiens</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Ornamental Kale">Ornamental
          Kale</image-search></span></p>
    <p id="h.3e8gvnb"><span>(1)<image-search query="Petunia">Petunia</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Marigold">Marigold</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Nasturtium">Nasturtium</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Pansy">Pansy</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Portulaca">Portulaca</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Annual salvia plant">Salvia</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="Snapdragon">Snapdragon</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="sweet william flower">Sweet
          William</image-search></span></p>
    <p><span>(1)<image-search query="Annual Verbena">Verbena</image-search></span><span>
      </span></p>
    <p id="h.1tdr5v4"><span>(1)<image-search query="Annual Vinca">Annual
          Vinca</image-search></span></p>
    <p><span>(1)<image-search query="zinnia flower">Zinnia</image-search></span>
    </p>
    <p><span>(1)<image-search query="creeping zinnia">Creeping
          Zinnia</image-search></span></p>
    <p class="c3 c10"><span class="c18 c24"></span></p>
    <p><span class="c18 c24">NATIVES</span></p>
    <p><span>(1)<image-search query="vaccinium angustifolium">Lowbush
          Blueberry</image-search></span></p>
    <p><span>(1)<image-search query="vaccinium corymbosum">Highbush
          Blueberry</image-search></span></p>
    <p><span>(1)<image-search query="arrowwood viburnum">Arrowwood
          Viburnum</image-search></span></p>
    <p><span>(1)<image-search query="clethra alnifolia">Clethra</image-search></span><span></span>
    </p>
    <p><span>(1)<image-search query="itea virginica">Itea</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="gaylussacia baccata">Huclkleberry</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="myrica pensylvanica">Northern
          Bayberry</image-search></span></p>
    <p><span>(1)<image-search query="cephalanthus occidentalis">Buttonbush</image-search></span>
    </p>
    <p><span>(1)<image-search query="possumhaw">Possumhaw</image-search></span>
    </p>
    <p><span>(1)<image-search query="festuca rubra">Red
          Fescue</image-search></span></p>
    <p><span>(1)<image-search query="andropogon virginicus">Broom
          Sedge</image-search></span></p>
    <p><span>(1)<image-search query="panicum virgatum">Switch
          Grass</image-search></span></p>
    <p><span>(1)<image-search query="schizachyrium scoparium">Little
          Bluestem</image-search></span></p>
    <p><span>(1)<image-search query="sorghastrum nutans">Indian
          Grass</image-search></span></p>
    <p><span>(1)<image-search query="carex pensylvanica">Pennsylvania
          Sedge</image-search></span></p>
    <p><span>(1)<image-search query="elymus hystrix">Bottlebrush
          Grass</image-search></span></p>
    <p><span>(1)<image-search query="aster">Aster</image-search></span>
    </p>
    <p><span>(1)<image-search query="echinacea purpurea">Echinacea</image-search></span>
    </p>
    <p><span>(1)<image-search query="butterfly weed plant">Butterfly
          Weed</image-search></span></p>
    <p><span>(1)<image-search query="yarrow flower">Yarrow</image-search></span>
    </p>
    <p><span>(1)<image-search query="bee balm">Bee
          Balm</image-search></span></p>
    <p><span>(1)<image-search query="marsh marigold">Marsh
          Marigold</image-search></span></p>
    <p><span>(1)<image-search query="turk’s cap lily">Turk’s
          Cap</image-search></span></p>
    <p class="c3 c10"><span class="c18 c24"></span></p>
    <p><span class="c18">VINES</span></p>
    <p><span>(1)<image-search query="five leaf akebia vine">Akebia
          Vine</image-search></span></p>
    <p><span>(1)<image-search query="Black-Eyed-Susan Vine">Black-Eyed-Susan
          Vine</image-search></span><span class="c14">(annual)</span></p>
    <p><span>(1)<image-search query="Bleeding Heart Vine">Bleeding
          Heart Vine</image-search> (1)<image-search query="tropaeolum peregrinum">Canary
          Bird Vine</image-search></span><span class="c14">(annual)</span></p>
    <p><span>(1)<image-search query="clematis">Clematis</image-search>
        <image-search query="Clematis ‘Henryi’">‘Henryi’
          White</image-search>
      </span></p>
    <p><span>
        <image-search query="clematis nelly moser">‘Nellie
          Moser’ Mauve/Pink</image-search>
      </span></p>
    <p><span>
        <image-search query="clematis niobe">‘Niobe’
          Ruby Red</image-search>
      </span></p>
    <p><span>
        <image-search query="clematis polish spirit">‘Polish
          Spirit’ Deep Purple</image-search>
      </span></p>
    <p><span>
        <image-search query="clematis jackmanii">‘Jackmanii’
          Dark Purple</image-search>
      </span></p>
    <p><span>
        <image-search query="clematis paniculata">Autumn,
          White</image-search>
      </span></p>
    <p><span>(1)<image-search query="Crossvine">Crossvine</image-search></span><span>
      </span></p>
    <p><span>(1)<image-search query="aristolochia durior">Dutchman’s
          Pipe</image-search></span></p>
    <p><span>(1)<image-search query="polygonum aubertii">Fleece
          Vine</image-search></span></p>
    <p><span>(1)<image-search query="hedera helix">Ivy,
          English</image-search></span></p>
    <p><span>(1)<image-search query="hedera helix 'Gold Heart'">Ivy
          ‘Gold Heart’</image-search></span></p>
    <p><span>(1)<image-search query="hedera canariensis">Ivy,
          Algerian</image-search></span></p>
    <p><span>(1)<image-search query="hedera helix 'Misty'">Ivy
          ‘Midget’ miniature</image-search></span></p>
    <p><span>(1)<image-search query="carolina jasmine">Carolina
          Jasmine</image-search></span></p>
    <p><span>(1)<image-search query="climbing hydrangea vine">Climbing
          Hydrangea</image-search></span></p>
    <p><span>(1)<image-search query="lonicera sempervirens">Honeysuckle
          Vine</image-search></span></p>
    <p><span>(1)<image-search query="actinidia kolomikta">Kiwi
          Vine</image-search></span></p>
    <p><span>(1) Grape Vine </span></p>
    <p><span>(1)<image-search query="mandevilla vine">Mandevilla</image-search></span><span></span>
    </p>
    <p><span>(1)<image-search query="moonflower vine">Moonflower
          Vine</image-search></span><span class="c14">(annual)</span></p>
    <p><span>(1)<image-search query="Poreclain Berry Vine">Poreclain
          Berry Vine</image-search></span></p>
    <p><span>(1)<image-search query="trumpet creeper vine">Trumpet
          Creeper</image-search></span></p>
    <p><span>(1)<image-search query="climbing roses">Climbing
          Rose</image-search></span></p>
    <p><span>(1)<image-search query="wisteria vine">Wisteria</image-search></span>
    </p>
  </article-wrapper>
</template>

<script>
import ImageSearch from '@/components/ImageSearch';

export default {
  components: {
    ImageSearch,
  },
};
</script>

<style lang="scss">
a:after {
  content: ' ';
}
</style>
